import config from '@spa/config';
import Fetch from '@spa/utils/fetch';
import utils from '@spa/utils';
import { Toast } from 'antd-mobile';
import { initialEnv, selectPayListType, createSuccessUrl, createForm } from './helper';
import { INITIAL_OPTIONS, PAY_LIST_OPTIONS, TOTAL_PAY_TYPE_MAP, PAY_PARAMS } from './consts';

import ajax from '@spa/utils/http';

import { miniToPay } from '@spa/utils/native-sdk';
/**
 * 支付服务工作台
 * @author liyfang
 */
class PaymentServiceBench {
  constructor() {
    this.initialOptions = { ...INITIAL_OPTIONS };
  }

  /**
   *
   * @param {INITIAL_OPTIONS} customOptions
   */
  async init(customOptions = {}) {
    this.initialOptions = { ...this.initialOptions, ...customOptions };

    // 先检查模式，判断是不是支付回来的，在mode为 hack:replace 跟 hack:push 时需要
    if (['hack:replace', 'hack:push'].includes(this.initialOptions.mode)) {
      // 判断是支付方还是查询方
      // TODO
    }
    if (this.initialOptions.target === 'payer') {
      // 初始化一下支付环境
      initialEnv(this.initialOptions, config);
      const { webviewEnv, clientBrand, frommini } = this.initialOptions;
      // 初始化支付列表类型
      this.initialOptions.payListType = selectPayListType(clientBrand, webviewEnv, frommini);
      // app 环境需要获取IP地址
      if (webviewEnv === 'app') {
        try {
          await Fetch(config.sohu.cityUrl, { suffix: 'js' }).then(() => {
            this.initialOptions.ip = window.returnCitySN.cip;
            console.log(window.returnCitySN);
          });
        } catch (error) {
          // Toast.info('IP获取失败');
          console.log(error);
        }
      }
    } else {
      this.paymentResult = {
        payStatus: 0,
      };
      // 查询支付的，需要冲地址栏截取相应的信息
      const urlObj = utils.urlToObj(location.search.slice(1));
      const { isWallet, payResult, format, method, sign, timestamp } = urlObj;
      let { orderId } = urlObj;
      if (!orderId) {
        orderId = window.localStorage.getItem('PAY_ORDER_ID');
      }
      window.localStorage.removeItem('PAY_ORDER_ID');
      this.paymentResult.orderId = orderId;
      if (isWallet || (format && method && sign && timestamp)) {
        // 钱包支付 isWallet=1  不需要请求接口，能调过来表示成功
        // 匹配是否支付宝支付回调，支付宝会在地址栏自己带参，截取几个参数匹配，表示支付宝回调
        // 详见 https://docs.open.alipay.com/203/107090/ 前台回跳参数
        this.paymentResult.payStatus = 1;
      } else if (config['client_env'] === 'mini') {
        // 判断是否小程序回来
        this.paymentResult.payStatus = payResult === '1' ? 1 : 2;
      } else {
        // 银联跟微信h5支付需要轮询接口，默认10次，前5次间隔1s，后5次间隔2s，超过次数之后强行失效
        this.paymentResult.payStatus = await this.loopQueryResult(orderId);
      }
    }
  }

  /**
   * 获取支付列表
   * @param {PAY_LIST_OPTIONS} options 可选参数
   */
  async getPayList(options = {}) {
    const costomOpt = { ...PAY_LIST_OPTIONS, ...options };
    let { orderId, busType, type, clientBrand, filterCategoryPayType, recommendCategoryPayType } = costomOpt;
    // 给个默认值 0 ，接口的默认值也是0
    busType = busType || 0;
    const params = {
      busType,
      type: type || this.initialOptions.payListType,
      clientBrand: clientBrand || this.initialOptions.clientBrand,
    };
    // 判断是否有订单id
    if (orderId) {
      params.orderId = orderId;
    }
    // 请求 /resource/m/pay/list 获取支付列表
    try {
      const result = await ajax.activityApi({
        url: '/resource/m/pay/list',
        params,
        method: 'get',
      });
      const { status, content } = result;
      if (status === 'SUCCESS') {
        // 处理下支付类型相关
        let { payList } = content;
        // 过滤需要过滤的支付类型
        filterCategoryPayType.forEach((categoryName) => {
          const payTypeList = this.getCategoryPayType(categoryName);
          payList = payList.filter((payInfo) => {
            if (payTypeList.includes(payInfo.payType)) {
              return false;
            }
            return true;
          });
        });
        // 设置推荐支付方式
        const recommendPayType = this.getCategoryPayType(recommendCategoryPayType);
        const walletPayType = this.getCategoryPayType('WALLET_PAY');
        payList.forEach((payInfo) => {
          if (recommendPayType.includes(payInfo.payType)) {
            payInfo.isRecommend = true;
          }
          // 如果是余额支付，添加个余额支付的标识
          if (walletPayType.includes(payInfo.payType)) {
            payInfo.isWallet = true;
          }
        });
        content.payList = payList;
        this.payList = payList;
        return content;
      }
    } catch (error) {
      console.log(error);
    }
    return {};
  }

  /**
   * 支付接口
   * @param {PAY_PARAMS} toPayParmas
   */
  async toPay(toPayParmas = {}) {
    const costomOpt = { ...PAY_PARAMS, ...toPayParmas };
    const { errorHandle } = costomOpt;
    try {
      const {
        payType,
        busType,
        orderId,
        redirectUrl,
        isPayMiniApi = false,
        isUpgrade = 0,
        isWxMini = false,
        walletRedirectMode,
        from,
        proposalNo,
        couponNum,
      } = costomOpt;
      const WALLET_PAY = this.getCategoryPayType('WALLET_PAY');
      const UNION_PAY = this.getCategoryPayType('UNION_PAY');
      const WECHAT_PAY = this.getCategoryPayType('WECHAT_PAY,');
      const isWallet = WALLET_PAY.includes(payType);
      const { redUrl, successUrl } = createSuccessUrl(redirectUrl, payType, orderId, {
        WALLET_PAY,
        UNION_PAY,
        WECHAT_PAY,
      });
      console.log('this.initialOptions.webviewEnv', this.initialOptions.webviewEnv);
      console.log('isWallet----------', isWallet);
      // 环境是小程序且走小程序支付接口
      if (this.initialOptions.webviewEnv === 'mini' && isPayMiniApi) {
        // 社群专享价走这
        // 升级券包才走，默认不走
        const miniUpgrade = '';
        miniToPay(
          orderId,
          busType,
          payType,
          successUrl,
          miniUpgrade,
          from, // 社群专享价专有字段
          proposalNo, // 社群专享价专有字段
          couponNum // 社群专享价专有字段
        );
        return;
      }
      // 如果是小程序环境，并且不是钱包支付,直接跳转小程序支付
      if (this.initialOptions.webviewEnv === 'mini' && !isWallet) {
        miniToPay(orderId, busType, payType, successUrl, isUpgrade);
        return;
      }
      // 如果是升级券包  且是在微信 余额支付 跳转小程序
      if (this.initialOptions.webviewEnv === 'mini' && isUpgrade && isWxMini && isWallet) {
        miniToPay(orderId, busType, payType, successUrl, isUpgrade);
        return;
      }
      // 其他的需要请求支付接口
      const data = {
        payType,
        busType,
        orderId,
        successUrl,
        ip: this.initialOptions.ip,
        handleMsg: errorHandle ? false : true,
      };
      const result = await ajax.activityApi({
        url: '/resource/m/order/topay',
        params: data,
      });
      if (result.status === 'SUCCESS') {
        const { needPay, payParams, payTypeId } = result.content;
        // 为所有的订单Id写入缓存
        window.localStorage.setItem('PAY_ORDER_ID', orderId);
        console.log('needPay, payParams, payTypeId =========', needPay, payParams, payTypeId);
        if (needPay) {
          // 需要判断有没有交易参数，没有提示返回信息
          if (payParams === null) {
            Toast.info('支付请求失败', 2, null, false);
            return;
          }
          switch (payTypeId) {
            case 2: {
              window.location.href = payParams.mwebUrl;
              break;
            }
            default: {
              const form = createForm(payParams);
              form.submit();
              break;
            }
          }
        } else {
          // 不需要支付的直接跳转结果页
          this.initialOptions.history && this.initialOptions.history[walletRedirectMode](redUrl);
        }
      } else {
        errorHandle && errorHandle(result);
      }
    } catch (error) {
      errorHandle && errorHandle(error);
      console.log(error);
    }
  }

  /** 查询支付结果 */
  async queryResult(orderId) {
    try {
      const result = await ajax.activityApi({
        url: '/resource/m/pay/query',
        params: {
          orderId,
        },
      });
      if (result.status === 'SUCCESS') {
        const { payStatus } = result.content;
        return payStatus;
      }
    } catch (error) {
      console.log(error);
    }
    // 请求失败返回未支付的状态
    return 0;
  }

  /** 轮询获取结果 */
  async loopQueryResult(orderId, count, delay) {
    if (!count) {
      count = this.initialOptions.loopCount;
    }
    if (!delay) {
      delay = this.initialOptions.loopDelay;
    }
    let payStatus = 0;
    let total = 0;
    let relDelay = delay instanceof Array ? delay[0] : delay;
    // let timer = Date.now();
    const packagingQuery = () => {
      return new Promise((resolve) => {
        setTimeout(
          () => {
            // const now = Date.now();
            // console.log(now - timer);
            // timer = now;
            this.queryResult(orderId).then((res) => {
              resolve(res);
            });
          },
          total === 0 ? 0 : relDelay
        );
      });
    };
    while ((payStatus = await packagingQuery()) === 0) {
      total++;
      if (total > count / 2 && delay instanceof Array) {
        relDelay = delay[1] || delay[0];
      }
      if (total > count) {
        payStatus = 2;
        break;
      }
    }
    return payStatus;
  }

  /**
   * 获取指定支付类型的 payType
   */
  getCategoryPayType(categoryName = '') {
    const list = TOTAL_PAY_TYPE_MAP[categoryName] || [];
    return list;
  }
}

export default PaymentServiceBench;
