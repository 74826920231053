/**
 * @author linzl
 */

// key 存储类型
export const keyTypes = {
  local: 'l',
  session: 's',
  cookie: 'c',
};

export const cacheKeys = {
  couponKey: 'coffee-redpack-mobile',
  merchantKey: 'coffee-mechant-coupon',
};

export default {
  // 存储手机号码
  [cacheKeys.couponKey]: {
    type: keyTypes.local,
    key: cacheKeys.couponKey,
    awCookie: false,
  },
  // 存储商家券信息
  [cacheKeys.merchantKey]: {
    type: keyTypes.session,
    key: cacheKeys.merchantKey,
    awCookie: false,
  },
};
