import React from 'react';
import ReactDOM from 'react-dom';

/** 初始化环境 */
export const initialEnv = (options, config) => {
  // 是否判断webviewEnv
  if (options.webviewEnv === '') {
    options.webviewEnv = config['client_env'];
  }
  // 判断品牌
  if (options.clientBrand === '') {
    options.clientBrand = config.clientBrand;
  }
  const { webviewEnv } = options;
  // 如果是小程序环境，设置 frommin选项
  if (webviewEnv === 'mini' && options.frommini === '') {
    options.frommini = config.frommini;
  }
};

/** 选择支付列表类型 */
export const selectPayListType = (clientBrand, webviewEnv, frommini) => {
  let payListType;
  // 判断支付列表type
  if (clientBrand === 'coffee') {
    if (webviewEnv === 'app') {
      payListType = 4;
    } else {
      if (frommini === 'mini') {
        payListType = 14;
      } else {
        payListType = 26;
      }
    }
  } else {
    if (webviewEnv === 'app') {
      payListType = 25;
    } else {
      payListType = 24;
    }
  }
  return payListType;
};

export const createSuccessUrl = (
  redirectUrl = '',
  payType,
  orderId,
  { WALLET_PAY, UNION_PAY, WECHAT_PAY }
) => {
  let redUrl = '';
  let tag = '';
  if (WALLET_PAY.includes(payType)) {
    tag = 'isWallet=1';
  } else if (UNION_PAY.includes(payType)) {
    tag = 'unionPay=1';
  } else if (WECHAT_PAY.includes(payType)) {
    tag = 'wechatH5=1';
  }
  // 需要替换
  if (redirectUrl.includes(':orderId')) {
    redUrl = redirectUrl.replace(':orderId', orderId);
    redUrl = `${redUrl}?${tag}`;
  } else {
    if (redirectUrl.includes('?')) {
      tag = `&${tag}`;
    } else {
      tag = `?${tag}`;
    }
    redUrl = `${redirectUrl}${tag}&orderId=${orderId}`;
  }
  console.log('url====', redUrl);
  return {
    redUrl,
    successUrl: `${window.location.origin}${redUrl}`,
  };
};
/**
 * 创建支付表单
 */
export const createForm = (formParams) => {
  const div = document.createElement('div');
  const form = ReactDOM.render(
    <form
      name={formParams.name || 'wapPayForm'}
      action={formParams.action}
      method={formParams.method}
    >
      {Object.keys(formParams.parameters || {}).map((key, index) => (
        <input
          type="hidden"
          name={key}
          key={'payForm_' + key + index}
          value={formParams.parameters[key]}
        />
      ))}
    </form>,
    div
  );
  document.body.appendChild(div);
  return form;
};
