/**
 * @author linzl
 * @description 微信jssdk相关功能(分享、定位等)
 * @todo 后续可以新增小程序分享初始化
 */

import config from '@spa/config';
import utils from '@spa/utils';
import AJAX from '@spa/utils/http';
import fetch from '@spa/utils/fetch';

// 微信基本配置
const wechatConfig = {
  cfgUrl: config?.wx?.cfgUrl || '/wxauth/cfg',
};

const getWxSignature = Symbol('wxSignature');
const initWxConfig = Symbol('initWxConfig');
const initWxJssdk = Symbol('initWxJssdk');

// 默认参数
const defaultOptions = {
  debug: false,
  jsApiList: ['checkJsApi', 'getLocation', 'updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareWeibo'],
  // 新增开放标签 https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/Wechat_Open_Tag.html
  openTagList: ['wx-open-launch-app', 'wx-open-subscribe', 'wx-open-launch-weapp'],
};

class Wechat {
  constructor() {
    this.currentUrl = '';
    // this[initWxConfig]();
  }

  /**
   * 初始化微信jsdk
   */
  [initWxJssdk]() {
    const isWxEnv = () => {
      const ua = navigator.userAgent.toLowerCase();
      const isWeixin = ua.indexOf('micromessenger') !== -1;
      if (isWeixin) {
        return true;
      } else {
        return false;
      }
    };

    if (!isWxEnv()) {
      console.error('请在【微信环境、开发者工具】中打开');
      return Promise.reject('非微信环境中，不初始化脚本');
    }
    if (window.wx) {
      this.wx = window.wx;
      return Promise.resolve();
    }
    return new Promise((resolve) => {
      fetch(config?.wx?.sdkUrl).then(() => {
        this.wx = window.wx;
        // 初始化微信配置
        this[initWxConfig]().then(() => {
          resolve();
        });
      });
    });
  }

  /**
   * 微信分享
   */
  setShareConfig(shareParams = {}) {
    const { title, link, img, desc } = shareParams;

    // 相对路径 图片处理
    // 如果传的图片是相对路径，表示是本地import形式传入的，需要主动加上当前环境域名(生产环境则是拼上cdn域名)
    const imgUrl = /(http|https):\/\/([\w.]+\/?)\S*/.test(img) ? img : `${window.location.origin}${img}`;

    const shareConfig = {
      title,
      link: (link || location.href).trim(), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl,
      desc,
    };

    this[initWxConfig]()
      .then(() => {
        const wx = this.wx;
        wx.updateAppMessageShareData(shareConfig);
        wx.updateTimelineShareData(shareConfig);
        wx.onMenuShareWeibo(shareConfig);
        // 分享给朋友
        // wx.onMenuShareAppMessage(shareConfig);
        // 分享到朋友圈（没有desc）
        // wx.onMenuShareTimeline(shareConfig);
        // wx.onMenuShareQQ(shareConfig);
        // wx.onMenuShareQZone(shareConfig);
      })
      .catch((msg) => {
        console.log(msg);
      });
  }

  /**
   * 获取微信定位
   */
  getLocation() {
    return new Promise((resolve, reject) => {
      this[initWxConfig]()
        .then(() => {
          this.wx?.getLocation({
            type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
            success: function (res) {
              resolve(res);
            },
            fail: function (err) {
              reject(err);
            },
            cancel: function (err) {
              reject(err);
            },
          });
        })
        .catch((msg) => {
          console.log(msg);
        });
    });
  }

  /**
   * 小程序内嵌h5 分享出小程序卡片的初始化操作  替换url参数
   * shareTitle  卡片标题
   * shareImg  卡片图
   * shareUrl 分享出来的链接
   * xxxxxx 其他参数也可以自行添加 都会被替换到url上
   * @param {object} shareConfig
   */
  initMiniProgramShare(shareConfig = {}) {
    if (config['client_env'] === 'mini') {
      // 初始化小程序分享
      Wechat.HANDLE_H5_IN_MINI_SHARE(shareConfig);
    }
  }
  static HANDLE_H5_IN_MINI_SHARE(params = {}) {
    if (params) {
      // 待替换的小程序分享地址地址
      let newUrl = Wechat.TO_REPLACE_MINI_SHARE_URL(params);
      utils.logger({
        tag: '小程序分享链接',
        content: newUrl,
      });
      window.history.replaceState({}, '', `${newUrl}`);
    }
  }

  /**
   * @description 待替换的小程序分享地址地址
   * @param {object} shareConfig
   * @summary search 需要拼接上新的 shareTitle、shareImg、shareUrl 等参数(如果存在的话) 和 拼接之前需要判断当前是否有 search，如果有要去掉 其已经存在的这些参数键值对
   * @returns 分享地址
   */
  static TO_REPLACE_MINI_SHARE_URL(shareConfig = {}) {
    const { search: urlSearch } = window.location;
    const allShareKeys = Object.keys(shareConfig);
    let sharedSearch = '';
    let replaceRegStr = '(&?)';

    allShareKeys.forEach((objKey) => {
      sharedSearch = sharedSearch + `${objKey}=${encodeURIComponent(shareConfig[objKey])}&`;
      replaceRegStr = replaceRegStr + `(&${objKey}=[\s\S]*&)`;
    }, '');

    let confirmedSearch = `?${sharedSearch}`; // 默认使用 sharedSearch

    // 如果当前 url 包含了 search，要先去掉 search 上面的分享参数
    // 然后再拼接上新的 sharedSearch
    if (urlSearch) {
      const removedSearch = urlSearch.replace(new RegExp(replaceRegStr), '');
      const sign = removedSearch === '?' ? '' : '&'; // 如果替换后的 search 只有 ?，那么 sign 设置为 '' 否则设置为 & (表示还有其他 search 内容)
      confirmedSearch = `${removedSearch}${sign}${sharedSearch}`;
    }
    const BASE_URL = window.location.origin + window.location.pathname;

    return `${BASE_URL}${confirmedSearch}`;
  }

  /**
   * 内部方法，获取微信签名
   */
  [getWxSignature]() {
    return AJAX.proxy({
      url: wechatConfig.cfgUrl,
      params: {
        url: location.href,
        _: Date.now(),
        path: '/',
        handleMsg: false,
      },
    }).then((result) => {
      if (!result?.success) {
        throw new Error(result?.message || '微信配置获取失败, 请稍后再试.');
      }
      const { appId, timestamp, nonceStr, signature } = result;
      return {
        appId,
        timestamp,
        nonceStr,
        signature,
      };
    });
  }

  /**
   * 内部方法，初始化微信配置
   */
  [initWxConfig]() {
    return new Promise((resolve, reject) => {
      // 如果当前页面已经授权过了，就无需初始化微信配置
      if (this.currentUrl === window.location.href) {
        return resolve();
      }
      this[initWxJssdk]().then(() => {
        this[getWxSignature]().then((wxSignature) => {
          this.wx.config(Object.assign({}, defaultOptions, wxSignature));
          this.wx.ready(() => {
            // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，
            // config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。
            // 对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
            this.currentUrl = window.location.href;
            resolve();
          });
          this.wx.error(function (res) {
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，
            // 也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
            reject(res);
          });
        });
      });
    });
  }
}

export default new Wechat();
