/**
 * @author linzl
 * @description 自定义scheme协议
 */
import config from '@spa/config';
import utils from '@spa/utils';

const SCHEMA_TYPE = '407';
class AppProtocol {
  constructor(props = {}) {
    const { preffix, type } = props;
    this.preffix = preffix || 'luckycoffee://host/?';
    this.type = type || SCHEMA_TYPE;
  }
  // app schema 协议 (ownType,自定义ownType)
  appProtocal(params, ownType) {
    // app 取自 config.default.js 里面的constants.terminals
    if (config['client_env'] !== 'app') {
      return;
    }
    const appObj = {
      type: ownType || this.type,
      entity: params,
    };
    const redirectToUrl = encodeURI(this.preffix + JSON.stringify(appObj));
    utils.logger({
      tag: 'APP协议跳转地址',
      content: redirectToUrl,
    });
    window.location.href = encodeURI(this.preffix + JSON.stringify(appObj));
  }

  // 打开新的schmea链接
  openNewSchemaUrl(path) {
    if (config['client_env'] !== 'app') {
      return;
    }
    // 前缀
    const PREFFIX_URL = 'luckycoffee://apphost';
    utils.logger({
      tag: 'APP协议跳转地址',
      content: `${PREFFIX_URL}${path}`,
    });
    window.location.href = `${PREFFIX_URL}${path}`;
  }
}

export default (props) => new AppProtocol(props);
