/**
 * @author linzl
 */

import utils from '@spa/utils';
/**
 * 处理广告上报参数
 * 自定义参数：ads(有该参数才做上报); gdt_cbut(有该参数需要区分新老用户，new为新用户)
 */
const handleAdsReportParams = (userType) => {
  // 广告上报
  const adParams = {};
  const urlParams = utils.getUrlParams() || {};
  // 如果广告类型为空，则不上报
  if (urlParams['ads']) {
    // 抖音参数 adid=__AID__&creativeid=__CID__&creativetype=__CTYPE__&clickid=__CLICKID__
    if (['douyin', 'toutiao'].includes(urlParams['ads'])) {
      adParams.params = {
        // 广告事件
        clickId: urlParams['clickid'],
      };
    }
    // 微信参数 wx_aid=8827363490&comp_id=862501890&gdt_vid=wx0gar4wdhazsz2200&wx_traceid=wx0gar4wdhazsz2200
    if (['weixin', 'qq'].includes(urlParams['ads'])) {
      adParams.params = {
        clickId: urlParams['gdt_vid'],
      };
    }
    // 需上报的用户类型
    adParams.params.gdtCbut = urlParams['gdt_cbut'] || '';
    // 广告地址
    adParams.params.url = encodeURIComponent(window.location.href);
    // 用户类型 0老用户、1新用户
    adParams.userType = userType || '';
    // 广告类型
    adParams.ads = urlParams['ads'];
  }
  return adParams;
};

export default {
  handleAdsReportParams,
};
