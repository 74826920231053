/**
 * @author linzl
 */
const utils = {};

/**
 * 获取URL search 参数
 */
utils.getUrlParams = (key) => {
  let search = decodeURIComponent(location.search.slice(1));
  if (!search) {
    return null;
  }
  search = utils.urlToObj(search);
  if (!key || typeof key !== 'string') {
    return search;
  } else {
    return search[key];
  }
};

/**
 * 更新url地址参数
 * 有则替换，无则添加
 * @param {string} paramKey 参数名
 * @param {string} paramValue 值
 * @returns 新的地址
 */
utils.updateUrlParams = (paramKey, paramValue, url = window.location.href) => {
  const urlParts = url.split('?');
  const queryParams = new URLSearchParams(urlParts[1]);
  if (queryParams.has(paramKey)) {
    queryParams.set(paramKey, paramValue);
  } else {
    queryParams.append(paramKey, paramValue);
  }

  return urlParts[0] + '?' + queryParams.toString();
};

/**
 * URL字符串转换成对象
 */
utils.urlToObj = (str, separator, connector) => {
  if (!str) {
    return false;
  }
  let newArr;
  let newObj = {};
  let i = 0;
  let l;
  separator = separator || '&';
  connector = connector || '=';
  newArr = str.split(separator);
  l = newArr.length;
  if (l === 1 && str.indexOf(connector) < 0) {
    return str;
  }
  for (; i < l; i += 1) {
    const curStr = newArr[i];
    const index = curStr.indexOf(connector);
    newObj[curStr.substr(0, index)] = curStr.substr(index + 1);
  }
  return newObj;
};

/**
 * 对象转换成URL字符串
 * @obj            对象
 * @separator    分隔符，默认为"&"
 * @connector    连接符，默认为"="
 */
utils.objToUrl = function (obj, separator, connector) {
  if (!obj) {
    return false;
  }
  let str = '';
  separator = separator || '&';
  connector = connector || '=';
  Object.keys(obj).forEach((key) => {
    str += separator + key + connector + obj[key];
  });
  str = str.substring(1);
  return str;
};

// 对象校验是否为空
utils.isEmpty = (obj) => {
  // 检验null和undefined
  if (!obj && obj !== 0 && obj !== '') {
    return true;
  }
  // 检验数组
  if (Array.prototype.isPrototypeOf(obj) && obj.length === 0) {
    return true;
  }
  // 检验对象
  if (Object.prototype.isPrototypeOf(obj) && Object.keys(obj).length === 0) {
    return true;
  }
  // 检查空字符串
  if (obj === '') {
    return true;
  }
  return false;
};

// 检验手机号码是否正确
utils.isMobile = (mobile = '') => {
  if (/^1[3-9]\d{9}$/.test(mobile.replace(/\s/g, ''))) {
    return true;
  }
  return false;
};

// 判断手机类型
utils.isIphonex = () => {
  if (typeof window !== 'undefined' && window) {
    return /iphone/gi.test(window.navigator.userAgent) && window.screen.height >= 812;
  }
  return false;
};

utils.throttle = function (fn, delay) {
  let timer = null;

  return function (...arg) {
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn(...arg);
    }, delay);
  };
};

utils.throttleNew = function (fn, delay) {
  let run = true;
  return function () {
    if (!run) {
      return;
    }
    run = false;
    setTimeout(() => {
      fn.apply(this, arguments);
      run = true;
    }, delay);
  };
};

utils.debounce = (func, wait, immediate = false) => {
  let timeout;
  // 控制是否立即触发变量
  let isInvoke = false;
  return function () {
    const context = this;
    const args = arguments;
    if (timeout) clearTimeout(timeout);
    if (immediate && !isInvoke) {
      func.apply(context, args);
      isInvoke = true;
    } else {
      timeout = setTimeout(() => {
        func.apply(context, args);
        isInvoke = false;
      }, wait);
    }
  };
};

utils.delay = (timeout = 0) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, timeout);
  });
};

// 将实际页面像素转为rem
utils.realPixelToRem = (realPixel) => {
  const virtualPixel = realPixel / window.devicePixelRatio;
  const remValue = (virtualPixel / window.rem) * window.dpr;
  return remValue;
};

// 处理px转rem (打包默认6s手机basefontSize 100px，同时 100px 对应 1rem )
utils.handlePxToRem = (pixel = 0) => {
  const basefontSizeNum = parseInt(document.documentElement.style.fontSize);
  const handleParse = (value) => {
    if (!Number.isNaN(parseInt(value))) {
      const pixelNum = parseInt(value);
      const realRemNum = (pixelNum * basefontSizeNum) / 100 / 100;
      return `${realRemNum}rem`;
    }
    return value;
  };
  if (typeof pixel === 'object') {
    Object.keys(pixel).forEach((key) => {
      pixel[key] = handleParse(pixel[key]);
    });
    return pixel;
  } else {
    return handleParse(pixel);
  }
};

/**
 * @description {} | {{}} 转为 span dom文本
 * @eg '111{aaa}222{{bbb}}333' => '111<span>aaa</span>222<span>bbb</span>333'
 * @eg '{{{}}}' => '<span>{</span>}' 【跟下面正则相关】
 * @returns html dom 文本
 */
utils.bracketToHtml = (str = '', className = '') => {
  return str?.replace(/{{(.*?)}}|{(.*?)}/g, (matchCtx, doubleBracketCtx, singleBracketCtx) => {
    return `<span class="${className}">${doubleBracketCtx || singleBracketCtx || ''}</span>`;
  });
};

/**
 * @description 时间戳转中文日期
 * @param {long} timestamp
 * @returns 中文日期
 */
utils.timestampToChineseDate = (timestamp) => {
  let date;
  if (timestamp > 0) {
    date = new Date(timestamp);
  } else {
    date = new Date();
  }
  // 获取年、月、日、时、分、秒
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();
  const millisecond = date.getMilliseconds();
  // 构造中文日期字符串
  const chineseDate = `${year}-${month}-${day} ${hour}:${minute}:${second},${millisecond}`;
  return chineseDate;
};

/**
 * @description 日志
 * @param {string} tag 标签
 * @param {object|string} text 内容
 * @param {string} style tag样式
 */
utils.logger = ({ tag = '', content, style = '' }) => {
  // 生产环境不打印
  if (process.env.NODE_LUCKY_ENV === 'prod' || utils.getUrlParams('lkdebug') === 'close') {
    return;
  }
  // 异步不阻塞流程
  setTimeout(() => {
    // 内容对象
    if (typeof content === 'object') {
      content = JSON.stringify(content, null, 2);
    }
    // 当前客户端时间
    const currentTime = `【${utils.timestampToChineseDate()}】`;
    // 标签
    if (tag && typeof tag === 'string') {
      tag = `【${tag}】`;
    } else {
      tag = '';
    }
    console.log(`%c ${currentTime}%c ${tag}==>`, `color:#00f;font-weight:bold`, `${style || 'color:#9932CD;font-weight:bold'}`, content);
  }, 0);
};

/**
 * @description 延迟
 * @param {number} delayTime 延迟时间
 */
utils.delay = async (delayTime = 0) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delayTime);
  });
};

/**
 * @description 获取屏幕缩放比率
 * @returns {object} 屏幕缩放比率信息
 */
utils.getFitScreenRatio = () => {
  // 兼容屏幕宽高比较大问题(折叠屏、ipad类型)
  // 临界值比率
  const THRESHOLD_RATIO = 0.6;
  const rootDomWidth = document.documentElement.getBoundingClientRect().width || 0;
  const rootDomHeight = document.documentElement.getBoundingClientRect().height || 1;
  // 字根大小， wap项目是2倍屏的设计方式，所以这里是100
  const rootDomFontSize = 100 * (rootDomWidth / 375);
  // 默认不缩放
  let scaleRatio = 1;
  if (rootDomWidth / rootDomHeight >= 1) {
    scaleRatio = rootDomHeight / rootDomWidth / 2;
  } else if (rootDomWidth / rootDomHeight > THRESHOLD_RATIO) {
    scaleRatio = 1 - (rootDomWidth / rootDomHeight - THRESHOLD_RATIO);
  }
  return {
    scaleRatio,
    rootFontSize: rootDomFontSize * scaleRatio,
  };
};
// 简易深拷贝
utils.deepClone = (obj) => {
  // 检查是否是null或undefined
  if (obj === null || obj === undefined) {
    return obj;
  }

  // 检查是否是基本数据类型 (String, Number, Boolean, Symbol, BigInt, Function)
  if (typeof obj !== 'object') {
    return obj;
  }

  // 检查是否是Date对象
  if (obj instanceof Date) {
    return new Date(obj.getTime());
  }

  // 检查是否是RegExp对象
  if (obj instanceof RegExp) {
    return new RegExp(obj.source, obj.flags);
  }

  // 检查是否是数组
  if (Array.isArray(obj)) {
    return obj.map((item) => utils.deepClone(item));
  }

  // 检查是否是普通对象
  if (obj instanceof Object) {
    const clonedObj = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        clonedObj[key] = utils.deepClone(obj[key]);
      }
    }
    return clonedObj;
  }

  // 如果对象类型未被覆盖，直接返回它
  return obj;
};

export default utils;
