'use strict';
import lodashFind from 'lodash/find';

const files = [];

class FetchFile {
  getElementId() {
    return `script_${Date.now()}_${Math.ceil(Math.random() * 100000)}`;
  }

  createScript(url, id) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.id = id;
    script.src = url;

    return script;
  }

  createStyle(src, id) {
    const style = document.createElement('link');
    style.rel = 'stylesheet';
    style.async = true;
    style.href = src;
    style.id = id;

    return style;
  }

  appendElement(element) {
    // 可能不存在scrpit,但一般都会有scrpit, 此处不再做判断
    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode && firstScript.parentNode.insertBefore(element, firstScript);
  }

  removeElement(id, url) {
    const element = document.getElementById(id);
    if (!element) {
      return;
    }
    const parent = element.parentNode;
    const urlIndex = lodashFind(files, (item) => item === url);
    if (urlIndex >= 0) {
      files.splice(urlIndex, 1);
    }
    try {
      parent && parent.removeChild(element);
    } catch (e) {
      // ignore
    }
  }

  fetchElement(url, options = {}) {
    return new Promise((resolve, reject) => {
      let element;
      const timeout = options.timeout || 5000;
      const eleId = options.id;
      if (url.indexOf('.js') > -1 || options.suffix === 'js') {
        element = this.createScript(url, eleId);
      } else if (url.indexOf('.css') > -1) {
        element = this.createStyle(url, eleId);
      }
      const that = this;
      const timeoutId = setTimeout(() => {
        reject(new Error(`Script request to ${url} timed out`));
        this.removeElement(eleId, url);
      }, timeout);

      const disableTimeout = (timeoutId) => clearTimeout(timeoutId);
      element.addEventListener('load', function (e) {
        resolve({ success: true, id: eleId });

        disableTimeout(timeoutId);
        that.removeElement(eleId, url);
      });

      element.addEventListener('error', function (e) {
        reject(new Error(`Script request to ${url} failed ${e}`));

        disableTimeout(timeoutId);
        that.removeElement(eleId, url);
      });

      this.appendElement(element);
    });
  }

  get(url, options = {}) {
    let scriptObj = {
      url,
      state: -1, // -1:没加载, 0:正在加载, 1:加载成功, 2:加载失败
    };

    scriptObj = lodashFind(files, (u) => u.url === url) || scriptObj;
    switch (scriptObj.state) {
      case 1:
        return Promise.resolve({ isload: true });
      case 0:
        return scriptObj.promise;
      default:
        if (scriptObj.state === -1) {
          files.push(scriptObj);
        }
        scriptObj.state = 0;
        options.id = options.id || this.getElementId();
        scriptObj.promise = this.fetchElement(url, options)
          .then((re) => {
            // 存储到files中, 标记成功
            scriptObj.state = 1;
            return re;
          })
          .catch((err) => {
            scriptObj.state = 2;
            throw err;
          });
        return scriptObj.promise;
    }
  }
}

/**
 * 默认输出
 * @param {String} url
 * @param {Object} options
 */
export default function (url, options) {
  return new FetchFile().get(url, options);
}
