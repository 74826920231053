/**
 * @author linzl
 * @description 自定义scheme协议
 */
import protocol from './base/protocol';

class LuckyProtocol {
  /**
   * @param {object} props
   * {preffix, type}
   */
  constructor(props) {
    this.protocol = protocol(props);
  }

  objectToUrlParams(obj) {
    if (!obj || typeof obj !== 'object') {
      return '';
    }
    let str = '';
    const beginSymbol = '?';
    const separator = '&';
    const connector = '=';
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (value !== '' && value !== null && value !== undefined && typeof value !== 'object') {
        str += separator + key + connector + obj[key];
      }
    });
    str = str.substring(1);
    return str?.length > 0 ? `${beginSymbol}${str}` : '';
  }
  /**
   * 打开扫码
   */
  openScanCode() {
    this.protocol.appProtocal({}, '413');
  }
  /**
   * 分享小程序到好友
   */
  miniToFriend(params = {}) {
    Object.assign(params, { channel: 'WXSceneSession', shareContentType: 1 });
    this.protocol.appProtocal(params);
  }

  /**
   * 分享网页到好友
   */
  urlToFriend(params = {}) {
    Object.assign(params, { channel: 'WXSceneSession', shareContentType: 0 });
    this.protocol.appProtocal(params);
  }

  /**
   * 分享网页到朋友圈
   */
  urlToCircle(params = {}) {
    Object.assign(params, { channel: 'WXSceneTimeline', shareContentType: 0 });
    this.protocol.appProtocal(params);
  }

  /**
   * 保存图片
   */
  urlToSavePic(params = {}) {
    Object.assign(params, {
      page: 'LuckinPosterCreateVC',
    });
    this.protocol.appProtocal(params, '408');
  }

  /**
   * 分享图片到好友
   */
  picToFriend(params = {}) {
    Object.assign(params, { channel: 'WXSceneSession', shareContentType: 0 });
    this.protocol.appProtocal(params);
  }

  /**
   * 分享图片到朋友圈
   */
  picToCircle(params = {}) {
    Object.assign(params, { channel: 'WXSceneTimeline', shareContentType: 0 });
    this.protocol.appProtocal(params);
  }

  /**
   * 查看订单（万事达支付使用）
   */
  goOrderDetailPage(params = {}) {
    this.protocol.appProtocal(params, '415');
  }

  /**
   * 查看万事达绑卡情况（万事达支付使用）
   */
  goBindDetailPage(params = {}) {
    this.protocol.appProtocal(params, '417');
  }

  /**
   * 跳转到菜单
   */
  goMenuPage(params = {}) {
    Object.assign(params, {
      page: 'ShoppingCartVC',
    });
    this.protocol.appProtocal(params, '408');
  }

  /**
   * 跳转到小程序指定页
   * @params path
   * eg: {path: "/pages/webview/common?needMobile=1&needLocation=1&noShare=1&redirect_h5=https%3A%2F%2Fm.lkcoffee.com%2Fmembercard%2Fhome"}
   */
  openMiniPage(params = {}) {
    this.protocol.appProtocal(params, '422');
  }

  /**
   * 打开发票
   */
  openInvoiceManage(params = {}) {
    this.protocol.appProtocal(params, '429');
  }

  /**
   * 打开商品详情
   */
  openProductDetail(params = {}) {
    const basePath = '/product/detail';
    const suffixPath = this.objectToUrlParams(params);
    this.protocol.openNewSchemaUrl(`${basePath}${suffixPath}`);
  }
}

export default new LuckyProtocol();
