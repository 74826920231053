/**
 * @author linzl
 * @description 数据埋点跟踪(新)
 * @wiki http://wiki.lkcoffee.com/pages/viewpage.action?pageId=4934273
 */
import utils from '@spa/utils';
import {
  TRACK_KEY_VALUE,
  EVENT_TYPES,
  MEMBERSHIP,
  WELFARE_CENTER,
  LUCKIN_CARD,
  ARRAY_DATA_INFO,
  MONITOR_TRACK,
  MONITOR_TRACK_API,
  DISCOUNT_ZONE_ACTIVITY,
} from './config';

/**
 * 手动上报
 * @param {string} eventCode 事件-编码
 * @param {object} trackProperties 事件-内容
 * @param {string} eventType 事件-触发类型
 */
const manualReport = (eventCode = '', trackProperties = {}, eventType = '') => {
  // 事件编码不存在，不执行上报
  if (!eventCode) {
    return;
  }
  // 上报的公共参数
  const properties = {
    referrer: document.referrer,
    url: location.href,
    ...trackProperties,
  };
  // 构造埋点数据结构
  const trackJson = {
    properties,
    event_code: eventCode,
    event_type: eventType,
  };
  if (typeof window.lucky?.trackInfoWithJson !== 'function') {
    // 存下上报数据,下次上报
    const trackHistory = JSON.parse(localStorage.getItem('trackHistory') || '[]');
    trackJson.event_time = `${Date.now()}`; //记录真实上报时间
    trackHistory.push(trackJson);
    localStorage.setItem('trackHistory', JSON.stringify(trackHistory));
    return;
  }
  // 检查缓存中是否有未上报数据,进行合并上报
  const trackData = JSON.parse(localStorage.getItem('trackHistory') || '[]');
  localStorage.removeItem('trackHistory');
  trackData.push(trackJson);
  trackData.forEach((item) => {
    window.lucky.trackInfoWithJson(item);
  });
};

/**
 * 处理过滤数据
 * @param {object} trackConfig 来源于配置
 */
const luckyTrack = (trackConfig = {}, trackData = {}) => {
  // 异步，不阻塞主流程 （看情况优化）
  setTimeout(() => {
    if (!trackConfig || !trackData) {
      return;
    }
    // 若要上报数组，则统一特殊处理
    if (trackData instanceof Array) {
      trackData = {
        [ARRAY_DATA_INFO.ARRAY_KEY]: trackData,
      };
    }
    // 事件-编码
    const eventCode = trackConfig?.[TRACK_KEY_VALUE.EVENT_CODE] || '';
    // 事件-内容
    let oldProperties = trackConfig?.[TRACK_KEY_VALUE.PROPERTIES] || {};
    if (typeof oldProperties === 'function') {
      oldProperties = oldProperties() || {};
    }
    // 深拷贝-简单处理，后期可用插件
    const trackProperties = JSON.parse(JSON.stringify(oldProperties)) || {};
    // 判断是否有数组(有数组特殊处理)
    if (trackProperties?.[ARRAY_DATA_INFO.HAS_ARRAY]) {
      // 取数组里面的对象
      const arrayObject = trackProperties?.[ARRAY_DATA_INFO.ARRAY_OBJECT] || {};
      // 是对象(本处兼容，因此冗余)
      if (Object.keys(arrayObject).length > 0) {
        Object.keys(arrayObject).forEach((arraryKey) => {
          const newTrackData = trackData[trackProperties[arraryKey]];
          if (newTrackData && newTrackData instanceof Array) {
            const trackNewArray = newTrackData.map((item) => {
              const newItem = {};
              for (const key in arrayObject[arraryKey]) {
                const value = arrayObject[arraryKey][key];
                if (item?.[value] === null || item?.[value] === undefined) {
                  newItem[key] = '';
                } else {
                  newItem[key] = item?.[value];
                }
              }
              return newItem;
            });
            trackProperties[arraryKey] = trackNewArray;
          }
        });
      }
    }

    // 事件-触发类型
    const eventType = trackConfig?.[TRACK_KEY_VALUE.EVENT_TYPE] || EVENT_TYPES.USER_ACTION;

    // 清空非必要参数上报
    delete trackProperties?.[ARRAY_DATA_INFO.HAS_ARRAY];
    delete trackProperties?.[ARRAY_DATA_INFO.ARRAY_OBJECT];

    // 最后一次遍历处理基本数据类型字段(一般是字符串)，数组不再处理，对象需要特殊处理
    for (const key in trackProperties) {
      const value = trackProperties[key];
      if (value instanceof Array) {
        // 如果是数组，代表前面已经处理
        continue;
      } else if (typeof value === 'object') {
        // 如果是对象，还需单独处理
        Object.keys(value).forEach((objectKey) => {
          let objectValue = trackData[objectKey];
          if (objectValue === null || objectValue === undefined) {
            objectValue = '';
          }
          // 当前对象key+子key
          trackProperties[key][objectKey] = objectValue;
        });
      } else if (trackData[value] === null || trackData[value] === undefined) {
        trackProperties[key] = '';
      } else {
        trackProperties[key] = trackData[value];
      }
    }
    if (eventType === EVENT_TYPES.APP_PERFORMCE_MNG) {
      // eventCode = 6则添加上报的公共参数
      const commonProperties = {
        referrer: document.referrer,
        url: window.location.href,
      };
      Object.assign(trackProperties, commonProperties);
    }
    utils.logger({
      tag: '执行上报, trackConfig',
      content: trackConfig,
    });
    utils.logger({
      tag: '执行上报, trackProperties',
      content: trackProperties,
    });
    // 手动上报
    manualReport(eventCode, trackProperties, eventType);
  }, 0);
};

export const manualTrackApiHandle = (type, data = {}) => {
  const trackProperties = {};
  let isReport = true;
  const apiHandle = (url) => {
    // 不在埋点白名单中不上报
    if (!url || !MONITOR_TRACK_API.includes(url)) {
      return false;
    }
    trackProperties.apiUrl = url;
    if (['apiSuccess', 'apiFail'].includes(type)) {
      trackProperties.zeusId = data?.data?.zeusId; //增加宙斯Id,方便定位
      trackProperties.startTime = data?.config?.params?._ || data?.config?.data?._; // 接口请求开始时间戳,方便日志快速定位
      trackProperties.duration = new Date() - (data?.config?.params?._ || data?.config?.data?._);
    }
    return true;
  };
  switch (type) {
    // 请求埋点事件
    case 'apiStart':
      isReport = apiHandle(data?.url);
      isReport && luckyTrack(MONITOR_TRACK.API_REQ_START, trackProperties);
      break;
    case 'apiSuccess':
      isReport = apiHandle(data?.config?.url);
      isReport && luckyTrack(MONITOR_TRACK.API_REQ_SUCCESS, trackProperties);
      break;
    case 'apiFail':
      isReport = apiHandle(data?.config?.url);
      isReport && luckyTrack(MONITOR_TRACK.API_REQ_FAIL, trackProperties);
      break;
  }
};
export { MEMBERSHIP, WELFARE_CENTER, LUCKIN_CARD, MONITOR_TRACK, DISCOUNT_ZONE_ACTIVITY };
export default luckyTrack;
