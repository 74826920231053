/**
 * @author linzl
 * @description 创建浏览器history
 */
import { BrowserRouter } from 'react-router-dom';

/**
 * 为history必须为同一个，否则browserRouter会出现地址变，组件未渲染问题
 * 给history 延长生命周期 (eg:采用闭包形式延长变量寿命等方式)
 * @by linzl
 */

const browserHistory = new BrowserRouter().history;
const tempHistoryPush = browserHistory.push;
browserHistory.push = (path) => {
  tempHistoryPush(path, { isRouterPushPage: true });
};
export { browserHistory };

export const browserHistoryPartner = new BrowserRouter({ basename: 'partner' }).history;
