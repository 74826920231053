/**
 * @author linzl
 * @description 瑞幸咖啡app默认常用的方法
 * @wiki http://wiki.lkcoffee.com/pages/viewpage.action?pageId=4940897
 */
import bridge from './base/bridge';
import eventEmitter from '@/spa/utils/event-emitter';
import luckyTrack, { MONITOR_TRACK } from '@spa/utils/lucky-track';
class LuckyBridge {
  constructor(namespace) {
    this.bridge = bridge(namespace);
    // 由于app返回必然调用这个方法，因此需要注册一下方法，不然sentry会一直报错
    window.beforeBackHandler = () => {};
  }

  isHarmony() {
    return navigator.userAgent.toLowerCase().includes('com.lucky.luckincoffee');
  }
  // 获取appversion 版本号
  async getAppVersion() {
    try {
      const methodName = 'getAppVersion';
      const currentAppVersion = await this.bridge.appBridge({
        methodName,
      });
      let finalAppversion;
      // 安卓版本号特殊，需要再做处理
      // pre : 4.5.0_pre
      // prod：4.5.0
      if (window.sysType.facility === 'android' || this.isHarmony()) {
        const versionStr = currentAppVersion?.split('_')[0] || '';
        const tempVersionArr = versionStr?.split('.');
        if (tempVersionArr && tempVersionArr.length) {
          finalAppversion = (tempVersionArr[0] || 0) * 1000 + (tempVersionArr[1] || 0) * 100 + Number((tempVersionArr[2] || '').padEnd(2, '0'));
        } else {
          finalAppversion = 0;
        }
      } else {
        finalAppversion = currentAppVersion;
      }
      return finalAppversion;
    } catch (error) {
      console.log(error);
      // 异常返回0版本
      return 0;
    }
  }

  // 设置标题
  setAppTitle(title = '') {
    document.title = title;
    const methodName = 'setWebTitle';
    eventEmitter.emit('setPageTitle', title);
    return this.bridge.appBridge({
      methodName,
      params: { title },
      bridgeType: this.bridge.bridgeType.call,
    });
  }
  // 跳转到菜单页
  gotoMenuView(params = {}) {
    const methodName = 'gotoMenuView';
    return this.bridge.appBridge({
      methodName,
      params,
    });
  }
  // 跳转到首页
  gotoHomePage() {
    const methodName = 'gotoHomePage';
    return this.bridge.appBridge({
      methodName,
    });
  }
  // 判断用户是否登陆
  async isLogin() {
    const methodName = 'isLogin';
    try {
      const isLogin = await this.bridge.appBridge({
        methodName,
      });
      return isLogin;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  // 跳转到登录页
  goLoginPage() {
    const methodName = 'gotoLoginView';
    return this.bridge.appBridge({
      methodName,
    });
  }
  // 关闭当前webview
  closeWebview() {
    const methodName = 'closeH5View';
    // 100毫秒为上报数据时间
    const WAIT_REPORT_TIME = 100;
    setTimeout(() => {
      this.bridge.appBridge({
        methodName,
      });
    }, WAIT_REPORT_TIME);
  }
  // 复制文本进剪切板
  copyToClipboard(word = '') {
    const methodName = 'copyClipboard';
    return this.bridge.appBridge({
      methodName,
      params: { key: word },
    });
  }

  /**
   * APP分享美图到朋友圈
   */
  async shareToCircle({ url, dataUri }) {
    const methodName = 'weChatShareImage';
    return this.bridge.appBridge({
      methodName,
      params: { url, dataUri },
    });
  }

  // 分享到微信朋友
  shareToWechat(params) {
    const methodName = 'weChatShare';
    params.channel = params.channel || 'WXSceneSession';
    params.shareContentType = params.shareContentType || 1;
    return this.bridge.appBridge({
      methodName,
      params,
    });
  }
  // 分享美图到朋友圈
  shareImgToWechatCircle(params) {
    const methodName = 'sharePOPToCircle';
    return this.bridge.appBridge({
      methodName,
      params,
    });
  }
  // 跳转至美图页
  goImgPage(params) {
    const methodName = 'gotoPOPLocalView';
    return this.bridge.appBridge({
      methodName,
      params,
    });
  }
  // 获取状态栏高度
  async getStatusBarHeight(params) {
    const methodName = 'getStatusBarHeight';
    try {
      const barHeight = await this.bridge.appBridge({
        methodName,
        params,
      });
      return barHeight;
    } catch (error) {
      console.log(error);
      return 0;
    }
  }
  // 变更状态栏的颜色（只有两种深色、浅色）
  setStatusBarColor(dark = false) {
    const methodName = 'updateStatusBar';
    return this.bridge.appBridge({
      methodName,
      params: { dark },
    });
  }
  // 隐藏状态栏 是否隐藏标题栏
  hideNavigationBar(isHide = true) {
    // '0': 不隐藏, '1': 隐藏
    const shouldHidden = isHide ? '1' : '0';
    const methodName = 'hideNavigationBar';
    return this.bridge.appBridge({
      methodName,
      params: { shouldHidden },
    });
  }
  // 新url 新webview
  openNewView({ url, title = '' }) {
    const methodName = 'openNewView';
    return this.bridge.appBridge({
      methodName,
      params: { url, title },
    });
  }
  // 保存图片
  saveImg(params = { url: '' }) {
    const methodName = 'saveImageToLocal';
    return this.bridge.appBridge({
      methodName,
      params,
    });
  }

  // 跳转商品详情
  gotoProductDetail(params = {}) {
    const methodName = 'gotoProductDetail';
    return this.bridge.appBridge({
      methodName,
      params,
    });
  }

  // 打开支付中心
  async openPayCenter(params = {}) {
    const methodName = 'openPayCenter';
    try {
      luckyTrack(MONITOR_TRACK.JS_BRIDGE_START, { methodName });
      await this.bridge.appBridge({
        methodName,
        params,
      });
      luckyTrack(MONITOR_TRACK.JS_BRIDGE_SUCCESS, { methodName });
    } catch (error) {
      luckyTrack(MONITOR_TRACK.JS_BRIDGE_FAIL, { methodName, error });
    }
  }

  /**
   * 打开原生支付、续费
   * @attention 已勾选协议传[]
   */
  async openPayCenterDialog(params = {}) {
    const methodName = 'openPayCenterDialog';
    luckyTrack(MONITOR_TRACK.JS_BRIDGE_START, { methodName });
    try {
      await this.bridge.appBridge({
        methodName,
        params,
      });
      luckyTrack(MONITOR_TRACK.JS_BRIDGE_SUCCESS, { methodName });
    } catch (error) {
      luckyTrack(MONITOR_TRACK.JS_BRIDGE_FAIL, { methodName, error });
    }
  }

  // 打开付费会员卡自动续费支付页面 { signType: "0", payCardNo: "xxx", entrance: number, redirect: string }
  async openAutoRenewPay(params = {}) {
    const methodName = 'openAutoRenew';
    try {
      luckyTrack(MONITOR_TRACK.JS_BRIDGE_START, { methodName });
      await this.bridge.appBridge({
        methodName,
        params,
      });
      luckyTrack(MONITOR_TRACK.JS_BRIDGE_SUCCESS, { methodName });
    } catch (error) {
      luckyTrack(MONITOR_TRACK.JS_BRIDGE_FAIL, { methodName, error });
    }
  }

  // 打开付费会员卡自动续费 签约列表
  async openSigningChannel(params = {}) {
    const methodName = 'openSigningChannel';
    try {
      luckyTrack(MONITOR_TRACK.JS_BRIDGE_START, { methodName });
      await this.bridge.appBridge({
        methodName,
        params,
      });
      luckyTrack(MONITOR_TRACK.JS_BRIDGE_SUCCESS, { methodName });
    } catch (error) {
      luckyTrack(MONITOR_TRACK.JS_BRIDGE_FAIL, { methodName, error });
    }
  }

  /**
   * 获取权限码
   */
  async getAuthorizationCode() {
    const methodName = 'applyForAuthorization';
    try {
      luckyTrack(MONITOR_TRACK.JS_BRIDGE_START, { methodName });
      let iosResult = await this.bridge.appBridge({
        methodName,
        params: {
          originUrl: window.encodeURIComponent(window.location.href),
        },
      });
      if (window.sysType.facility === 'android' || this.isHarmony()) {
        iosResult = await new Promise((resolve) => {
          window.onAuthRenewResult = (res) => {
            // 清除当前监听
            delete window.onAuthRenewResult;
            resolve(res);
          };
        });
      }
      // 根据桥接数据来判断走成功还是失败上报
      if (iosResult?.code) {
        luckyTrack(MONITOR_TRACK.JS_BRIDGE_SUCCESS, { methodName });
      } else {
        luckyTrack(MONITOR_TRACK.JS_BRIDGE_FAIL, { methodName });
      }
      return iosResult;
    } catch (error) {
      luckyTrack(MONITOR_TRACK.JS_BRIDGE_FAIL, { methodName, error });
      return {};
    }
  }

  // 获取当前app定位信息
  async getLocationInfo() {
    const methodName = 'getLocationInfo';
    try {
      const result = await this.bridge.appBridge({
        methodName,
      });
      const locationInfo = typeof result === 'string' ? JSON.parse(result) : result;
      return locationInfo;
    } catch (error) {
      return {};
    }
  }

  /**
   * var obj = {"medalLevelId":"xxxx",}
   * 参数说明：参数透传 medalLevelId  为勋章等级ID
   */
  saveTaskMedalPoster(params = {}) {
    const methodName = 'saveTaskMedalPoster';
    return this.bridge.appBridge({
      methodName,
      params,
    });
  }

  /**
   * 获取用户基本信息  appversion>= 5.0.05
   * (5.0.05 只含有门店id、门店名称、自提外送方式)
   */
  async getBaseInfo() {
    try {
      const methodName = 'getBaseInfo';
      const baseInfoStr = await this.bridge.appBridge({
        methodName,
      });
      return baseInfoStr ? JSON.parse(baseInfoStr) : {};
    } catch (error) {
      console.log(error);
      return {};
    }
  }

  /**
   * 获取会员id
   * @returns 会员id
   */
  async getUniqueId() {
    try {
      const methodName = 'getUniqueId';
      const resultStr = await this.bridge.appBridge({
        methodName,
      });
      return resultStr;
    } catch (error) {
      console.log(error);
      return '';
    }
  }

  /**
   * 调用微信授权
   * @returns 会员id
   */
  async wxAuthorized() {
    try {
      // 此桥接安卓和ios实现方式不同
      // ios是异步方法，直接返回字符串  成功： 'onAuthorized'  失败： 'onAuthorizedFail'
      // 安卓需要我们先在window下注册好成功和失败回调  成功时执行onAuthorized  失败时执行onAuthorizedFail

      const methodName = 'wxAuthorized';
      if (['ipad', 'iphone'].includes(window.sysType.facility)) {
        const resultStr = await this.bridge.appBridge({
          methodName,
          timeout: 5000,
        });
        const result = resultStr === 'onAuthorized';
        return result;
      } else {
        const getResult = new Promise((resolve, reject) => {
          window.onAuthorized = () => {
            resolve(true);
          };
          window.onAuthorizeFail = () => {
            reject('微信授权失败');
          };
          // 避免无限等待
          setTimeout(() => {
            reject('微信授权超时');
          }, 5000);
        });

        this.bridge.appBridge({
          methodName,
        });
        const result = await getResult;
        return result;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  // 设置导航栏分享按钮显示隐藏
  switchShareButton(showStatus = true) {
    // 是否展示
    const methodName = 'switchShareButton';
    return this.bridge.appBridge({
      methodName,
      params: { showStatus },
      bridgeType: this.bridge.bridgeType.call,
      // 特殊处理
      type: 'appShare',
    });
  }

  // 监听导航栏右边分享按钮点击触发
  onAppShareAction(callBack) {
    const methodName = 'onLuckinAppShare';
    return this.bridge.appBridge({
      methodName,
      params: {},
      bridgeType: this.bridge.bridgeType.register,
      registerCallback: callBack,
      // 特殊处理
      type: 'appShare',
    });
  }

  /**
   * APP中h5弹层数据
   * @returns app从接口拿到的数据，格式为json
   */
  async getWebDialogInfo() {
    try {
      const methodName = 'getWebDialogInfo';
      luckyTrack(MONITOR_TRACK.JS_BRIDGE_START, { methodName });
      const result = await this.bridge.appBridge({ methodName });
      if (typeof result === 'string') {
        // json字符串转对象
        return JSON.parse(result);
      } else if (typeof result === 'object') {
        // 直接返回json对象
        return result;
      } else {
        throw new Error();
      }
    } catch (err) {
      return {};
    }
  }

  // ios陀螺仪事件，安卓没有
  async registerShakeListener({ timeout = 60000 }) {
    try {
      const methodName = 'registerShakeListener';
      // 由于是异步且需要根据实际情况判断摇一摇等操作
      // 第一个timeout是桥接调用超时，第二个timeout为app原生监听事件的超时时间，原生默认60秒
      return this.bridge.appBridge({ methodName, timeout, params: { timeout } });
    } catch (err) {
      return {};
    }
  }
  // 跳转并打开购物车
  addToCart(params) {
    const methodName = 'addToCart';
    console.log('方法名', methodName);
    // let a = {
    //   open: 1,
    //   list: [
    //     {
    //       productId: '1111',
    //       skuCode:"111",
    //       amount:1,
    //       checked: 1,
    //       addTime: 1111 // 时间戳
    //     }
    //   ]
    // }
    return this.bridge.appBridge({
      methodName,
      params,
    });
  }

  // 调起选择自提门店列表(选择门店全局生效)
  // params = { isOverAll: 1 } 默认覆盖全局门店
  // 参数说明：参数透传isOverAll = 1 覆盖全局门店；isOverAll = 0 不覆盖全局门店
  async gotoShopListAndSave(isOverAll = 1) {
    const methodName = 'gotoShopListAndSave';
    if (window.sysType.facility === 'android' || this.isHarmony()) {
      this.bridge.appBridge({
        methodName,
        params: {
          isOverAll,
        },
      });
      // android回调约定
      return new Promise((resolve, reject) => {
        window['onShopSelected'] = (data) => {
          resolve(data);
        };
      });
    } else {
      // ios直接调用Promise中的回调
      return await this.bridge.appBridge({
        methodName,
        timeout: 60000,
        params: {
          isOverAll,
        },
      });
    }
  }
}

export default (namespace) => new LuckyBridge(namespace);
