import storage from './storage';
import storageKey from './storageKey';

/**
 * 通用服务，用于页面的临时存储
 */
class Cache {
  // 获取
  get(key) {
    return storage.get(storageKey[key]);
  }

  // 赋值
  set(key, value) {
    return storage.set(storageKey[key], value);
  }

  // 清除
  remove(key) {
    return storage.remove(storageKey[key]);
  }
}

export default new Cache();
