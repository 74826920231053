/**
 * @author linzl
 * @description 手动埋点上报数据统一配置
 */

// 埋点上报关键字段-保证引用和使用一致
export const TRACK_KEY_VALUE = {
  // 事件-编码
  EVENT_CODE: 'EVENT_CODE',
  // 事件-触发类型
  EVENT_TYPE: 'EVENT_TYPE',
  // 事件-内容
  PROPERTIES: 'PROPERTIES',
};

// 事件-触发类型
export const EVENT_TYPES = {
  // 用户行为触发
  USER_ACTION: '1',
  // 在用户进入某个场景后，由客户端自行统计的逻辑，包括曝光的自动统计、banner图的自动切换，弹窗弹出事件等
  BROWSE_LIST_MENU: '2',
  // 页面访问自动触发
  PAGE_VIEW: '3',
  // 在客户端开启或关闭时触发的采集，包括启动、关闭本身的统计，以及启动时触发的其他逻辑,app_list的采集
  APP_LIST: '4',
  // 在客户端接收到回调后的埋点，回调类埋点基本都涉及和第三方的交互，涉及到支付等
  CALLBACK: '5',
  // 应用性能管理触发（异常、加载）
  APP_PERFORMCE_MNG: '6',
};

// 数组数据信息
export const ARRAY_DATA_INFO = {
  HAS_ARRAY: 'HAS_ARRAY',
  ARRAY_OBJECT: 'ARRAY_OBJECT',
  ARRAY_KEY: 'LUCKY_TRACK_ARRAY_KEY',
};

const DEFAULT_OPTION = Symbol('default_option');
/**
 * @description 成长会员模块
 * @wiki http://wiki.lkcoffee.com/pages/viewpage.action?pageId=168085604
 */
export const MEMBERSHIP = {
  // 默认选项
  [DEFAULT_OPTION]: {
    // 会员等级编号
    levelNo: 'levelNo',
    // 会员等级名称
    levelName: 'levelName',
    // 是否当前会员等级
    currentLevel: 'currentLevel',
    // 权益名称
    benefitName: 'benefitName',
    // 权益图片
    benefitImg: 'benefitImg',
    // 小蓝豆数量
    memberBeanNum: 'memberBeanNum',
    // 是否保级状态
    keepLevel: 'keepLevel',
    // 引导升杯图片
    upgradeGuideImgUrl: 'upgradeGuideImgUrl',
    // 兑换背景图片地址
    exchangePopupPic: 'exchangePopupPic',
    // 是否加lucky（0否 1是）
    joinedLucky: 'joinedLucky',
  },
  // 点击 子权益
  CLICK_BENEFIT_ICON: {
    // 事件-编码
    [TRACK_KEY_VALUE.EVENT_CODE]: 'growingequity_ck',
    // 事件-触发类型
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.USER_ACTION,
    // 事件-内容
    [TRACK_KEY_VALUE.PROPERTIES]: () => {
      return {
        ...MEMBERSHIP[DEFAULT_OPTION],
      };
    },
  },
  // 点击 引导升杯弹窗-我知道||兑换
  CLICK_POP_WINDOW: {
    [TRACK_KEY_VALUE.EVENT_CODE]: 'growingequity_ck_window',
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.USER_ACTION,
    [TRACK_KEY_VALUE.PROPERTIES]: () => {
      return {
        ...MEMBERSHIP[DEFAULT_OPTION],
        // 窗口类型 1：升杯 2：兑换 3：其他
        windowType: 'windowType',
        // 默认为空
        num: 'exchangeNum',
        // 窗口图片链接
        windowPiclinkUrl: 'windowPiclinkUrl',
      };
    },
  },
  // 浏览 弹窗
  BROWSE_POP_WINDOW: {
    [TRACK_KEY_VALUE.EVENT_CODE]: 'growingequity_bw_window',
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.BROWSE_LIST_MENU,
    [TRACK_KEY_VALUE.PROPERTIES]: () => {
      return {
        ...MEMBERSHIP[DEFAULT_OPTION],
        // 窗口类型 1：升杯 2：兑换 3：其他
        windowType: 'windowType',
        // 窗口图片链接
        windowPiclinkUrl: 'windowPiclinkUrl',
      };
    },
  },
};

/**
 * @description 福利中心
 * @wiki http://wiki.lkcoffee.com/pages/viewpage.action?pageId=168085441
 */
export const WELFARE_CENTER = {
  [DEFAULT_OPTION]: {
    // 内容枚举  1: 券  2: 卡
    contentType: 'contentType',
    // 付费会员: contentInfo 数组包含: planNo: '主卡信息id', picLink: '图片地址', contentNo: '子卡信息id', price: '付费卡价格',
    // 付费券包: contentInfo 数组包含: planNo: '分层方案id', picLink: '图片地址', contentNo: '优惠券编号', price: '券包价格',
    contentInfo: 'contentInfo',
  },
  // 浏览 付费券包||付费会员卡
  BROWSE_PAYMENT_COUPON_LUCKINCARD: {
    [TRACK_KEY_VALUE.EVENT_CODE]: 'welfarecenter_bw_top',
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.BROWSE_LIST_MENU,
    [TRACK_KEY_VALUE.PROPERTIES]: () => {
      return {
        // 重点: 数组
        contentInfo: [
          {
            // 分层方案id
            planNo: '',
            // 图片地址
            picLink: '',
            // 优惠券编号
            contentNo: '',
            // 价格
            price: '',
          },
        ],
        ...WELFARE_CENTER[DEFAULT_OPTION],
      };
    },
  },
  // 点击 付费券包||付费会员卡
  CLICK_PAYMENT_COUPON_LUCKINCARD: {
    [TRACK_KEY_VALUE.EVENT_CODE]: 'welfarecenter_ck_top',
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.USER_ACTION,
    [TRACK_KEY_VALUE.PROPERTIES]: () => {
      return {
        // 重点: 对象
        contentInfo: {
          // 主卡信息id
          planNo: '',
          // 图片地址
          picLink: '',
          // 子卡信息id
          contentNo: '',
          // 单价默认为空
          price: '',
        },
        ...WELFARE_CENTER[DEFAULT_OPTION],
      };
    },
  },
  // 点击 领取免费优惠券
  CLICK_GET_FREE_COUPON: {
    [TRACK_KEY_VALUE.EVENT_CODE]: 'getcoupon_ck_freecoupon',
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.USER_ACTION,
    [TRACK_KEY_VALUE.PROPERTIES]: {
      // 用户标签
      userlabel: 'userLevel',
      // 优惠券方案编码
      couponno: 'sendProposalNo',
    },
  },
  // 点击 去喝一杯
  CLICK_TO_DRINK: {
    [TRACK_KEY_VALUE.EVENT_CODE]: 'getcoupon_ck_todrink',
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.USER_ACTION,
    [TRACK_KEY_VALUE.PROPERTIES]: {
      // 用户标签
      userlabel: 'userLevel',
    },
  },
  // 浏览 打开社群lucky
  BROWSE_WECHAT_LUCKY: {
    [TRACK_KEY_VALUE.EVENT_CODE]: 'getcoupon_bw_lucky',
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.BROWSE_LIST_MENU,
    [TRACK_KEY_VALUE.PROPERTIES]: {},
  },
  // 浏览 升级券包
  BROWSE_UPGRADE_COUPON: {
    [TRACK_KEY_VALUE.EVENT_CODE]: 'getcoupon_bw_upcoupon',
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.BROWSE_LIST_MENU,
    [TRACK_KEY_VALUE.PROPERTIES]: {
      // 券包编号
      proposalno: 'proposalno',
      // 价格
      price: 'price',
    },
  },
  // 点击 付费券包(进入时，立马触发) [付费券包确认订单页]
  CLICK_INIT_PAYMENT_COUPON: {
    [TRACK_KEY_VALUE.EVENT_CODE]: 'getcoupon_ck_upcoupon',
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.USER_ACTION,
    [TRACK_KEY_VALUE.PROPERTIES]: {
      // 券包编号
      proposalno: 'proposalNo',
      // 价格
      price: 'couponBagPrice',
    },
  },
  // 浏览 落地页 [付费券包确认订单页]
  BROWSE_PAYMENT_COUPON: {
    [TRACK_KEY_VALUE.EVENT_CODE]: 'couponpackage#confirmorder#page_view',
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.BROWSE_LIST_MENU,
    [TRACK_KEY_VALUE.PROPERTIES]: {
      // 券包编号
      couponplan_no: 'proposalNo',
      // 显示是否正常 1正常0异常（只有券包信息不为空的时候才正常）
      isright: 'isPageViewRight',
    },
  },
  // 点击 购买 [付费券包确认订单页]
  CLICK_BUY_PAYMENT_COUPON: {
    [TRACK_KEY_VALUE.EVENT_CODE]: 'couponpackage#confirmorder#pay',
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.USER_ACTION,
    [TRACK_KEY_VALUE.PROPERTIES]: {
      // 券包编号
      couponplan_no: 'proposalNo',
    },
  },
  // 浏览 商品曝光 [付费券包确认订单页]
  BROWSE_GOODS_PAYMENT_COUPON: {
    [TRACK_KEY_VALUE.EVENT_CODE]: 'couponpackage_bw_commodity',
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.BROWSE_LIST_MENU,
    [TRACK_KEY_VALUE.PROPERTIES]: {
      // 商品信息 (目前公共方法仅支持一个层级)
      // goods: [{item_name: ''},{item_name: ''}]
      goods: 'goods',
    },
  },
  // 点击 触点展示位
  CLICK_DISPLAY_POSTION: {
    [TRACK_KEY_VALUE.EVENT_CODE]: 'welfarecenter_ck_banner',
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.USER_ACTION,
    [TRACK_KEY_VALUE.PROPERTIES]: {
      // 策略编号
      tacticNo: 'tacticNo',
      // 方案编号
      planCode: 'planCode',
    },
  },
  // 点击 任务信息
  CLICK_TASK_INFO: {
    [TRACK_KEY_VALUE.EVENT_CODE]: 'welfarecenter_ck_task',
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.USER_ACTION,
    [TRACK_KEY_VALUE.PROPERTIES]: {
      // 任务方案id
      taskPlanId: 'taskPlanId',
      // 策略id
      tacticGroupId: 'tacticGroupId',
      // 子策略id
      tacticId: 'tacticId',
      // 主标题
      mainTitle: 'mainTitle',
      // 奖励描述
      prizeDesc: 'prizeDesc',
      // 进度描述信息
      progressDesc: 'progressDesc',
      // 展示分类（1：排行任务；2：下单任务；3：勋章任务）
      tabType: 'tabType',
      // 展示奖励类型（1：优惠券；2：勋章）
      rewardTypeTrack: 'rewardTypeTrack',
      // 1、去喝一杯  2、其他热区
      ckType: 'ckType',
    },
    CONSTANT: {
      TRACK_CLICK_TYPE: {
        TO_DRINK: 1,
        OTHER: 2,
      },
    },
  },
};

/**
 * @description 自由卡|会员卡
 * @wiki http://wiki.lkcoffee.com/pages/viewpage.action?pageId=173295188
 */
export const LUCKIN_CARD = {
  // 字段意思见: http://wiki.lkcoffee.com/pages/viewpage.action?pageId=97249168
  [DEFAULT_OPTION]: {
    // 模块标题
    moduleTitle: 'moduleTitle',
    // 展示位小类code
    positionCode: 'positionCode',
    // 跳转链接
    link: 'link',
    // 位置顺序
    location: 'location',
    // 策略编号
    tacticNo: 'tacticNo',
    // 方案ID
    planId: 'planId',
    // 展示顺序
    sortNum: 'sortNum',
    // 策略ID
    tacticId: 'tacticId',
    // 是否微信授权 枚举 0: 未授权  1: 已授权
    wxIsAuthorization: 'wxIsAuthorization',
    // 模板Id
    moduleId: 'moduleId',
    // contentType 枚举 1: 图片、2：商品、3：自由卡、4：优惠券、5：链接、6：文本
    contentType: 'contentType',
    // 内容的id
    contentId: 'contentId',
    // 内容值
    contentValue: 'contentValue',
  },
  // 浏览 自由卡 展示位
  BROWSE_DISPLAY_POSITION: {
    [TRACK_KEY_VALUE.EVENT_CODE]: 'resourceposition_bw',
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.BROWSE_LIST_MENU,
    [TRACK_KEY_VALUE.PROPERTIES]: () => {
      return {
        // 是否有数组
        [ARRAY_DATA_INFO.HAS_ARRAY]: true,
        // 数组里面的对象值
        [ARRAY_DATA_INFO.ARRAY_OBJECT]: {
          // 这个值要和下面(实际上报)的一样
          [ARRAY_DATA_INFO.ARRAY_KEY]: {
            ...LUCKIN_CARD[DEFAULT_OPTION],
          },
        },
        // 重点: 数组
        basedata: ARRAY_DATA_INFO.ARRAY_KEY,
      };
    },
  },
  // 点击 自由卡 展示位
  CLICK_DISPLAY_POSITION: {
    [TRACK_KEY_VALUE.EVENT_CODE]: 'resourceposition_ck',
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.USER_ACTION,
    [TRACK_KEY_VALUE.PROPERTIES]: () => {
      return {
        basedata: {
          ...LUCKIN_CARD[DEFAULT_OPTION],
        },
      };
    },
  },
};

/**
 * @description 折扣专区 活动页
 */
export const DISCOUNT_ZONE_ACTIVITY = {
  // 页面预览
  ACTIVITY_VIEW: {
    [TRACK_KEY_VALUE.EVENT_CODE]: 'web_cmdty_bw',
    [TRACK_KEY_VALUE.PROPERTIES]: () => {
      return {
        department_id: 'department_id',
        goods: 'goods',
        is_login: 'is_login',
        total_price: 'total_price',
        total_discount_price: 'total_discount_price',
      };
    },
  },

  // 点击商品
  CLICK_PRODUCT: {
    [TRACK_KEY_VALUE.EVENT_CODE]: 'web_cmdty_ck',
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.USER_ACTION,
    [TRACK_KEY_VALUE.PROPERTIES]: () => {
      return {
        department_id: 'department_id',
        cmdty_code: 'cmdty_code',
        cmdty_name: 'cmdty_name',
        cmdty_label: 'cmdty_label',
        content_value: 'content_value',
        cmdty_price: 'cmdty_price',
        cmdty_discount_price: 'cmdty_discount_price',
      };
    },
  },

  // 点击加入购物车
  CLICK_ADD_CART: {
    [TRACK_KEY_VALUE.EVENT_CODE]: 'web_ck_shoppingcart_sensors',
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.USER_ACTION,
    [TRACK_KEY_VALUE.PROPERTIES]: () => {
      return {
        department_id: 'department_id',
        goods: 'goods',
        is_login: 'is_login',
        total_price: 'total_price',
        total_discount_price: 'total_discount_price',
      };
    },
  },
};

/* 
   手动埋点-P0 设置
*/
/**
 * @description 请求埋点
 * @wiki
 */
export const MONITOR_TRACK = {
  // 请求开始
  API_REQ_START: {
    // 事件-编码
    [TRACK_KEY_VALUE.EVENT_CODE]: 'fe_api_req_start',
    // 事件-触发类型
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.APP_PERFORMCE_MNG,
    // 事件-内容
    [TRACK_KEY_VALUE.PROPERTIES]: () => {
      return {
        apiUrl: 'apiUrl',
      };
    },
  },
  // 请求成功
  API_REQ_SUCCESS: {
    // 事件-编码
    [TRACK_KEY_VALUE.EVENT_CODE]: 'fe_api_req_success',
    // 事件-触发类型
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.APP_PERFORMCE_MNG,
    // 事件-内容
    [TRACK_KEY_VALUE.PROPERTIES]: () => {
      return {
        apiUrl: 'apiUrl',
        zeusId: 'zeusId', //宙斯id
        duration: 'duration', //请求耗时
        startTime: 'startTime', //请求开始时间戳
      };
    },
  },
  // 请求失败
  API_REQ_FAIL: {
    // 事件-编码
    [TRACK_KEY_VALUE.EVENT_CODE]: 'fe_api_req_fail',
    // 事件-触发类型
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.APP_PERFORMCE_MNG,
    // 事件-内容
    [TRACK_KEY_VALUE.PROPERTIES]: () => {
      return {
        apiUrl: 'apiUrl',
        zeusId: 'zeusId', //宙斯id
        duration: 'duration', //请求耗时
        startTime: 'startTime', //请求开始时间戳
      };
    },
  },
  // H5调用三方桥接开启
  JS_BRIDGE_START: {
    // 事件-编码
    [TRACK_KEY_VALUE.EVENT_CODE]: 'fe_jsbridge_run_start',
    // 事件-触发类型
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.APP_PERFORMCE_MNG,
    // 事件-内容
    [TRACK_KEY_VALUE.PROPERTIES]: () => {
      return {
        methodName: 'methodName', //桥接名称
      };
    },
  },
  // H5调用三方桥接成功
  JS_BRIDGE_SUCCESS: {
    // 事件-编码
    [TRACK_KEY_VALUE.EVENT_CODE]: 'fe_jsbridge_run_success',
    // 事件-触发类型
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.APP_PERFORMCE_MNG,
    // 事件-内容
    [TRACK_KEY_VALUE.PROPERTIES]: () => {
      return {
        methodName: 'methodName', //桥接名称
      };
    },
  },
  // H5调用三方桥接失败
  JS_BRIDGE_FAIL: {
    // 事件-编码
    [TRACK_KEY_VALUE.EVENT_CODE]: 'fe_jsbridge_run_fail',
    // 事件-触发类型
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.APP_PERFORMCE_MNG,
    // 事件-内容
    [TRACK_KEY_VALUE.PROPERTIES]: () => {
      return {
        methodName: 'methodName', //桥接名称
      };
    },
  },
  // 支付宝sdk方法开始
  SDK_MY_START: {
    // 事件-编码
    [TRACK_KEY_VALUE.EVENT_CODE]: 'fe_sdk_my_start',
    // 事件-触发类型
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.APP_PERFORMCE_MNG,
    // 事件-内容
    [TRACK_KEY_VALUE.PROPERTIES]: () => {
      return {};
    },
  },
  // 支付宝sdk方法成功
  SDK_MY_SUCCESS: {
    // 事件-编码
    [TRACK_KEY_VALUE.EVENT_CODE]: 'fe_sdk_my_success',
    // 事件-触发类型
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.APP_PERFORMCE_MNG,
    // 事件-内容
    [TRACK_KEY_VALUE.PROPERTIES]: () => {
      return {};
    },
  },
  // 支付宝sdk方法失败
  SDK_MY_FAIL: {
    // 事件-编码
    [TRACK_KEY_VALUE.EVENT_CODE]: 'fe_sdk_my_fail',
    // 事件-触发类型
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.APP_PERFORMCE_MNG,
    // 事件-内容
    [TRACK_KEY_VALUE.PROPERTIES]: () => {
      return {};
    },
  },
  // 微信sdk方法开始
  SDK_WX_START: {
    // 事件-编码
    [TRACK_KEY_VALUE.EVENT_CODE]: 'fe_sdk_wx_start',
    // 事件-触发类型
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.APP_PERFORMCE_MNG,
    // 事件-内容
    [TRACK_KEY_VALUE.PROPERTIES]: () => {
      return {};
    },
  },
  // 微信sdk方法成功
  SDK_WX_SUCCESS: {
    // 事件-编码
    [TRACK_KEY_VALUE.EVENT_CODE]: 'fe_sdk_wx_success',
    // 事件-触发类型
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.APP_PERFORMCE_MNG,
    // 事件-内容
    [TRACK_KEY_VALUE.PROPERTIES]: () => {
      return {};
    },
  },
  // 微信sdk方法失败
  SDK_WX_FAIL: {
    // 事件-编码
    [TRACK_KEY_VALUE.EVENT_CODE]: 'fe_sdk_wx_fail',
    // 事件-触发类型
    [TRACK_KEY_VALUE.EVENT_TYPE]: EVENT_TYPES.APP_PERFORMCE_MNG,
    // 事件-内容
    [TRACK_KEY_VALUE.PROPERTIES]: () => {
      return {};
    },
  },
};
// 手动埋点-接口白名单
export const MONITOR_TRACK_API = [
  '/resource/m/promo/activity/judgeCode', //活动标识是否有效
  '/resource/m/promo/activity/judgeSendActivity', //判断邀请码状态
  '/resource/m/promo/activity/send', //领取优惠券
  '/resource/m/lotteryDraw/detail', //获取抽奖活动详情
  '/resource/m/lotteryDraw/action', //抽奖接口
  '/resource/m/promo/h5/activity/send', //领取优惠券
  '/resource/m/sys/base/validcode', //发送验证码
  '/resource/m/sys/base/verifycodeTongdun', //发送验证码含同盾信息
  '/resource/m/sys/base/sliderVerify', // 验证滑块验证码接口
  '/resource/user/card/detailTab', //获取所有自由卡信息
  '/resource/market/receiveCoupon/enter', //福利中心基本数据信息
  '/resource/market/receiveCoupon/receiveCouponDetail', //券包信息(免费、付费)
  '/resource/market/receiveCoupon/receiveCouponSend', //领取免费券包
  '/resource/market/ticket/order/confirm', //付费券包确认
  '/resource/user/task/taskMedalList', //任务列表
  '/resource/m/sys/app/taskopeningcitys', //获取任务限定城市
  '/resource/m/shop/list', //获取任务门店
  '/resource/user/task/taskMedalHistory', //查看历史任务
  '/resource/user/task/taskMedalDetail', //查看任务详情
  '/resource/user/task/medalWallPage', //勋章列表
  '/resource/m/fissionRedPacket/get', //领取裂变券
  '/resource/m/fissionRedPacket/detailInfo', //裂变活动详情
  '/resource/m/user/wechat/jsapiTicket', //微信授权
  '/resource/core/v1/product/newmemberarea', //券包、商品列表
  '/resource/m/growUp/main', //用户会员等级信息
  '/resource/m/growUp/receiveBirthdayBenefits', //领取生日优惠券
  '/resource/m/growUp/blueBeansExchange', //小蓝豆兑换升杯
  '/resource/m/growUp/benefits', //等级权益详情说明
  '/resource/m/growUp/blueBeans', //小蓝豆获取明细
  '/resource/m/growUp/queryLotteryInfo', //获取等级会员抽奖信息
  '/resource/m/growUp/drawLottery', //等级会员小蓝豆抽奖
  '/resource/m/order/fulfill', //交易履约信息
  '/resource/m/order/blockchain', //交易履约上链信息
  '/resource/m/sys/base/h5video', //视频制作直播
  '/resource/m/promotion/electroniccard/splitCard', //获取赠送礼品卡
  '/resource/m/promotion/giftCardPresent/splitGiftCard', //获取赠送幸运送
  '/resource/m/promotion/electroniccard/receiveCard', //领取礼品卡
  '/resource/m/promotion/coffeeStorePresent/receiveCoffeeStore', //领取幸运送
];
