/**
 * @description 小程序和app的桥接，对外只暴露一个方法
 */

import config from '@spa/config';

import appApi from '@/spa/utils/app-api';
import miniApi, { miniRouteKey } from '@/spa/utils/mini-api';

const luckyBridge = appApi.createLuckyBridge();
const luckyProtocol = appApi.luckyProtocol;

// 客户端类型
const CLIENT_TYPES = {
  // app
  APP: 'app',
  // 微信小程序
  MINI: 'mini',
  // 普通浏览器
  BROWSER: 'browser',
};

// 在小程序客户端
const IN_MINI_CLIENT = config['client_env'] === CLIENT_TYPES.MINI;
// 在app客户端
const IN_APP_CLIENT = config['client_env'] === CLIENT_TYPES.APP;

// 菜单
const goMenu = () => {
  if (IN_APP_CLIENT) {
    luckyProtocol.goMenuPage();
  } else if (IN_MINI_CLIENT) {
    miniApi.navToPage({
      routeKey: miniRouteKey.MENU,
      openMethod: 'redirectTo',
    });
  }
};

// 礼品卡
const goGiftCard = () => {
  if (IN_APP_CLIENT) {
    luckyProtocol.goMenuPage();
  } else if (IN_MINI_CLIENT) {
    miniApi.navToPage({
      routeKey: miniRouteKey.GIFTCARD,
    });
  }
};

// 幸运送
const goLuckySend = () => {
  if (IN_APP_CLIENT) {
    luckyProtocol.goMenuPage();
  } else if (IN_MINI_CLIENT) {
    miniApi.navToPage({
      routeKey: miniRouteKey.LUCKYSEND,
    });
  }
};

// 关闭当前页面
const closeCurrentPage = () => {
  if (IN_APP_CLIENT) {
    luckyBridge.closeWebview();
  } else if (IN_MINI_CLIENT) {
    miniApi.closeWebView();
  }
};

/**
 * 设置页面标题
 */
const setWebTitle = (title) => {
  document.title = title;
  if (IN_APP_CLIENT) {
    luckyBridge.setAppTitle(title);
  }
};

// app中跳转到自由卡菜单
const goMenuTabLuckincard = () => {
  if (IN_APP_CLIENT) {
    console.log('【goMenuTabLuckincard】config.appversion', config['appversion']); // TODO: del
    if (config['appversion'] >= 5000) {
      window.location.href = 'luckycoffee://apphost/tab/menu?tabType=2';
    } else {
      luckyBridge.gotoMenuView();
    }
  } else if (IN_MINI_CLIENT) {
    miniApi.navToPage({
      routeKey: miniRouteKey.MENU,
    });
  }
};

/**
 * 小程序去支付
 * @param {string} orderId 订单id
 * @param {number} busType 商品类型  @see 'http://wiki.lkcoffee.com/pages/viewpage.action?pageId=8869607#id-%E6%94%AF%E4%BB%98%E6%8E%A5%E5%8F%A3-2.%E6%94%AF%E4%BB%98%E6%8E%A5%E5%8F%A3Greensinceapp1.0api100'
 * @param {string} payType 支付方式
 * @param {string} redirect 重定向地址 需要完整的地址，带上域名端口
 * @param {number} isUpgrade 是否为升级券包支付（要小程序完成发商家券动作）0否 1是
 * @param {string} from 社群专享价特有标志 from='exclusivePrice'则表示是社群专享价，其他表示否
 * @param {string} proposalNo 社群专享价券包方案编号 proposalNo='XXXX'则表示是社群专享价编号，默认为proposalNo=''
 * @param {number} couponNum 社群专享价券包数量
 */
const miniToPay = (orderId, busType, payType, redirect, isUpgrade = 0, from = '', proposalNo = '', couponNum = 0) => {
  redirect = encodeURIComponent(redirect);
  miniApi.redirectTo(
    `/pages/index/pageTransfer?entry=h5pay&orderId=${orderId}&busType=${busType}&payType=${payType}&redirect=${redirect}&isUpgrade=${isUpgrade}&from=${from}&proposalNo=${proposalNo}&couponNum=${couponNum}`
  );
};

/**
 * 小程序领商家券
 */
const miniToMerTicket = (params, pageOpenOrigin) => {
  const paramKeys = Object.keys(params);
  let urlSearch = '';
  for (let key of paramKeys) {
    urlSearch += `&${key}=${params[key]}`;
  }
  const entryParam = pageOpenOrigin === 14 ? 'couponSuccess' : 'sendCoupon';
  const url = `/pages/index/pageTransfer?entry=${entryParam}${urlSearch}`;
  console.log('小程序领商家券miniToMerTicket url----', url);
  miniApi.redirectTo(url);
};

/**
 * 全量获取用户基本信息
 */
function getAllBaseInfo() {
  return luckyBridge.getBaseInfo();
}

/**
 * 从用户基础信息桥接获取当前选择的门店信息
 */
async function getShopInfo() {
  try {
    const baseInfo = await getAllBaseInfo();
    if (baseInfo) {
      const { shopInfo = {} } = baseInfo;
      return shopInfo;
    }
  } catch (err) {
    return {};
  }
}

/**
 * @description 打开新的窗口(根据链接的形式以及所处环境，决定打开的方式)
 * @param {string} url 地址
 * @param {string} title 标题
 */
const openNewViewIfWeb = (url = '', title = '') => {
  if (!url) {
    return;
  }

  if (url.startsWith('/pages') && IN_MINI_CLIENT) {
    // 小程序中 打开 小程序链接
    miniApi.openMiniPage(url);
  } else if (url.startsWith('/pages') && IN_APP_CLIENT) {
    // app中 打开 小程序链接
    luckyProtocol.openMiniPage({
      path: url,
    });
  } else if (url.startsWith('http') && IN_MINI_CLIENT) {
    // 小程序中 打开 小程序内嵌h5页面
    miniApi.navToPage({
      routeKey: miniRouteKey.WEBVIEW,
      params: {
        webviewUrl: url,
        noShare: 1,
        noformat: 1,
      },
    });
  } else if (url.startsWith('http') && IN_APP_CLIENT) {
    // app中 打开 app内嵌h5页面
    luckyBridge.openNewView({
      url,
      title: title ? title : '瑞幸咖啡',
    });
  } else {
    // 普通浏览器中 打开 h5页面
    location.href = url;
  }
};

// app中判断用户是否已登陆
const judgeUserIsLoginInApp = async () => {
  if (IN_APP_CLIENT) {
    return await luckyBridge.isLogin();
  } else {
    return false;
  }
};

// 跳转到app登陆页
const goLoginPage = () => {
  if (IN_APP_CLIENT) {
    return luckyBridge.goLoginPage();
  }
};

// app中进行微信授权
const callWxAuthorized = async () => {
  if (IN_APP_CLIENT) {
    return await luckyBridge.wxAuthorized();
  } else {
    return false;
  }
};

/**
 * 处理是喝一杯还是下载app
 */
const handleDrinkOrDownload = (downloadUrl = '') => {
  // 是否为 小程序环境 (frommini)
  if (IN_MINI_CLIENT) {
    miniApi.navToPage({
      routeKey: miniRouteKey.MENU,
    });
  } else if (IN_APP_CLIENT) {
    luckyProtocol.goMenuPage();
  } else {
    handleDownload((downloadUrl = ''));
  }
};

/**
 * 其他环境的下载
 */
const handleDownload = (appDownloadurl) => {
  if (window.sysType && window.sysType.browser === 'weixin') {
    let weixinAPPUrl = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.lucky.luckyclient';
    window.location.href = weixinAPPUrl;
  } else if (navigator.userAgent.includes('HUAWEI')) {
    // 瑞幸app 华为应用商店
    // const harmonyAppWebUrl = 'https://appgallery.huawei.com/app/C100192545';
    const harmonyAppUrl = 'market://details?id=com.lucky.luckyclient';
    // 瑞幸app 唤醒的schema
    const launchApp = 'luckycoffee://host/?{"type":"408","entity":{"page":"ShoppingCartVC"}}';
    window.location.href = launchApp;

    const timer = setTimeout(() => {
      // 鸿蒙注册的jsbridge对象方法
      if (window['harmony-bridge-open-app'] && window['harmony-bridge-open-app'].call) {
        window['harmony-bridge-open-app'].call(harmonyAppUrl);
      }
    }, 1500);
    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        timer.clearTimeout();
        document.removeEventListener('visibilitychange', () => {});
      }
    });
  } else {
    window.location.href = appDownloadurl || `https://m.${window.luckinDomain}/app/download`;
  }
};

// 关闭h5 返回原生页面
const backToNativePage = () => {
  // 是否为app环境
  if (IN_APP_CLIENT) {
    luckyBridge.closeWebview();
  } else {
    window.history.back();
  }
};

// 跳转商品详情
const navToProductPage = (product) => {
  const params = {
    productId: product.productId,
    maxAmount: product.maxAmount,
    from: product.from || '',
  };
  if (IN_APP_CLIENT) {
    luckyProtocol.openProductDetail({
      spu: product.productId,
      from: product.from || '',
    });
  } else {
    miniApi.navToPage({
      routeKey: miniRouteKey.PRODUCT_DETAIL,
      params,
    });
  }
};

export {
  goMenu,
  goLuckySend,
  goGiftCard,
  setWebTitle,
  miniToPay,
  miniToMerTicket,
  goMenuTabLuckincard,
  closeCurrentPage,
  getShopInfo,
  openNewViewIfWeb,
  judgeUserIsLoginInApp,
  goLoginPage,
  callWxAuthorized,
  handleDrinkOrDownload,
  backToNativePage,
  navToProductPage,
};
