/**
 * 初始化的配置项
 */
export const INITIAL_OPTIONS = {
    /** 模式 */
    mode: '',
    totalMode: ['', 'hack:replace', 'hack:push'],
    /** 目标，支付方或者查询方 payer ｜ searcher */
    target: 'payer',
    ip: '',
    // 下面参数可以自定义，为空自动获取
    webviewEnv: '',
    clientBrand: '',
    frommini: '',
    // 查询结果需要
    loopCount: 10,
    loopDelay: [1000, 2000],
    history: null
};

/** 支付列表参数 */
export const PAY_LIST_OPTIONS = {
    orderId: null,
    busType: null,
    type: null,
    clientBrand: null,
    /**
     * 需要过滤的支付类型
     * @type {string[]}
     */
    filterCategoryPayType: [],
    /** 推荐的支付类型 */
    recommendCategoryPayType: '',
};

export const PAY_PARAMS = {
    payType: '',
    busType: 0,
    orderId: null,
    /**
     * 重定向地址，必须是站内地址。
     * 不带域名的，会自己加上当前的域名。
     * 支持 :orderId 参数,会替换成订单id到地址，没有的话会在query参数拼上。
     * 例如：/result/:orderId  ===> /result/123456
     * */
    redirectUrl: '',
    /** 钱包支付成功后重定向方式 push ｜ replace */
    walletRedirectMode: 'push'
};

/** 咖啡支付宝支付 */
const COFFEE_ALI_PAY = ['14', '15', '16'];
/** 咖啡支付宝小程序支付 */
const COFFEE_ALI_MINI_PAY = ['126'];
/** 咖啡支付宝全部支付 */
const COFFEE_ALI_ALL_PAY = [...COFFEE_ALI_PAY, ...COFFEE_ALI_MINI_PAY];
/** 咖啡微信支付 */
const COFFEE_WECHAT_PAY = ['24', '25', '26'];
/** 咖啡微信公众号支付 */
const COFFEE_WECHAT_PUBLICITY_PAY = ['29'];
/** 咖啡微信小程序支付 */
const COFFEE_WECHAT_MINI_PAY = ['214'];
/** 咖啡微信全部支付 */
const COFFEE_WECHAT_ALL_PAY = [...COFFEE_WECHAT_PAY, ...COFFEE_WECHAT_PUBLICITY_PAY, ...COFFEE_WECHAT_MINI_PAY];
/** 咖啡银联支付 */
const COFFEE_UNION_PAY = ['34', '35', '36'];
/** 咖啡钱包支付 */
const COFFEE_WALLET_PAY = ['74', '75', '76', '711', '714'];
/** 鹿茶支付宝支付 */
const TEA_ALI_PAY = ['122', '123', '124', '125'];
/** 鹿茶支付宝全部支付 */
const TEA_ALI_ALL_PAY = [...TEA_ALI_PAY];
/** 鹿茶微信支付 */
const TEA_WECHAT_PAY = ['222', '223', '224', '225'];
/** 鹿茶微信小程序支付 */
const TEA_WECHAT_MINI_PAY = ['224'];
/** 鹿茶微信公众号支付 */
const TEA_WECHAT_PUBLICITY_PAY = ['29'];
/** 鹿茶微信全部支付 */
const TEA_WECHAT_ALL_PAY = [...TEA_WECHAT_PAY, ...TEA_WECHAT_MINI_PAY, ...TEA_WECHAT_PUBLICITY_PAY];
/** 鹿茶银联支付 */
const TEA_UNION_PAY = ['325', '322', '323'];
/** 鹿茶钱包支付 */
const TEA_WALLET_PAY = ['725', '722', '723', '711', '724'];

// 支付合集
/** 国际信用卡支付 */
const INTERNATIONAL_CARD = ['64', '65', '66', '625'];
/** 微信支付 */
const WECHAT_PAY = [...COFFEE_WECHAT_ALL_PAY, ...TEA_WECHAT_ALL_PAY];
/** 支付宝支付 */
const ALI_PAY = [...COFFEE_ALI_ALL_PAY, ...TEA_ALI_ALL_PAY];
/** 银联支付 */
const UNION_PAY = [...COFFEE_UNION_PAY, ...TEA_UNION_PAY];
/** 钱包支付 */
const WALLET_PAY = [...COFFEE_WALLET_PAY, ...TEA_WALLET_PAY];
/** 小程序支付 */
const MINI_PAY = [...COFFEE_ALI_MINI_PAY, ...COFFEE_WECHAT_MINI_PAY, TEA_WECHAT_MINI_PAY];

/** 全部支付类型集合 */
export const TOTAL_PAY_TYPE_MAP = {
    INTERNATIONAL_CARD,
    WECHAT_PAY,
    ALI_PAY,
    UNION_PAY,
    WALLET_PAY,
    // 支付小项
    COFFEE_ALI_PAY,
    COFFEE_ALI_MINI_PAY,
    COFFEE_ALI_ALL_PAY,
    COFFEE_WECHAT_PAY,
    COFFEE_WECHAT_PUBLICITY_PAY,
    COFFEE_WECHAT_MINI_PAY,
    COFFEE_WECHAT_ALL_PAY,
    COFFEE_UNION_PAY,
    COFFEE_WALLET_PAY,
    TEA_ALI_PAY,
    TEA_ALI_ALL_PAY,
    TEA_WECHAT_PAY,
    TEA_WECHAT_MINI_PAY,
    TEA_WECHAT_PUBLICITY_PAY,
    TEA_WECHAT_ALL_PAY,
    TEA_UNION_PAY,
    TEA_WALLET_PAY,
    MINI_PAY,
};
