/**
 * @author linzl
 * @description 数据埋点跟踪
 */

import AJAX from '@spa/utils/http';
import config, { HTTP_METHOD_CONFIG } from '@spa/config';
import sensors from './sensors';
const API_URL = {
  pageTrack: '/resource/m/shence/webscan',
  clickTrack: '/resource/m/shence/webclick',
};

class DataTrack {
  // 通過event獲取節點信息
  getDataTrackAttr(event) {
    const el = event.target;
    if (!el) {
      return {};
    }
    return {
      title: document.title,
      elementContent: el.innerHTML,
      elementId: el.id,
      elementTargetUrl: el.getAttribute('href') || el.getAttribute('data-url'),
      elementName: el.tagName,
      elementClassName: el.className,
      elementType: el.getAttribute('type'),
      elementSelector: el.id
        ? '#' + el.id
        : el.className
        ? '.' + el.className
        : null,
    };
  }
  /**
   * @param {object} el
   * title/content/id/url
   */
  uploadSensorTrack(el = null) {
    // 判断哪些不需要数据埋点
    const disableRoutes =
      config['luckyfe.mkt.disablesensortrack_activities'] || [];
    if (
      config['luckyfe.mkt.disablesensortrack'] === 1 ||
      disableRoutes.some((item) =>
        window.location.href.includes(item.activityName)
      )
    ) {
      return;
    }
    let apiUrl;
    if (el) {
      apiUrl = API_URL.clickTrack;
      sensors.clientInfo.title = el.title || '';
      sensors.clientInfo.elementContent = el.content || '';
      sensors.clientInfo.elementId = el.id || '';
      // 参数值url 需要做encodeURIComponent
      sensors.clientInfo.elementTargetUrl = encodeURIComponent(el.url) || '';
    } else {
      apiUrl = API_URL.pageTrack;
      sensors.clientInfo.openid = config.wx_openid || '';
      sensors.clientInfo.title = document.title || '';
      sensors.clientInfo.url = encodeURIComponent(location.href); // 页面地址
      sensors.clientInfo.urlPath = location.pathname; // 页面路径
    }
    // 上报数据
    AJAX.capi({
      url: apiUrl,
      params: {
        loading: false,
        handleMsg: false,
        needError: false,
        ...sensors.clientInfo,
      },
      method: HTTP_METHOD_CONFIG.post,
    });
  }
}

export default new DataTrack();
