/**
 * @author linzl
 * @description app桥接
 * @wiki http://wiki.lkcoffee.com/pages/viewpage.action?pageId=4940897
 */
import config from '@spa/config';

// resolve白名单桥接方法名
const WHITE_LIST_RESOLVE_BRIDGE_METHODS = [
  'setWebTitle',
  'gotoMenuView',
  'gotoHomePage',
  'gotoLoginView',
  'closeH5View',
  'copyClipboard',
  'weChatShare',
  'sharePOPToCircle',
  'gotoPOPLocalView',
  'updateStatusBar',
  'hideNavigationBar',
  'openNewView',
  'saveImageToLocal',
  'gotoProductDetail',
  'openPayCenter',
  'openPayCenterDialog',
  'openSigningChannel',
  'saveTaskMedalPoster',
  'switchShareButton',
];
/**
 * app 桥接
 */
class AppBridge {
  constructor(namespace) {
    this.namespace = namespace || 'nativeSupportApp';
    this.appType = window.sysType?.facility || '';
    this.bridgeType = {
      register: Symbol('register'),
      call: Symbol('call'),
    };
  }
  async getAppBridge() {
    const webviewBridgePromise = new Promise((resolve) => {
      // 前提是app原生是使用 WebViewJavascriptBridge库 来实现 js bridge
      if (window.WebViewJavascriptBridge) {
        resolve(window.WebViewJavascriptBridge);
      } else {
        // window.WVJBCallbacks 是一个用于 iOS 中 UIWebView 和 WKWebView 与 JavaScript 通信的全局变量
        if (window.WVJBCallbacks) {
          return window.WVJBCallbacks.push((bridge) => resolve(bridge));
        } else {
          window.WVJBCallbacks = [(bridge) => resolve(bridge)];
          // 初始化桥接代码，用来触发WebViewJavascriptBridgeReady事件
          const WVJBIframe = document.createElement('iframe');
          WVJBIframe.style.display = 'none';
          // wvjbscheme://__BRIDGE_LOADED__ 为默认值，同时需要注意这是协议类型，三方APP可能会合规
          WVJBIframe.src = 'wvjbscheme://__BRIDGE_LOADED__';
          document.documentElement.appendChild(WVJBIframe);
          setTimeout(() => {
            document.documentElement.removeChild(WVJBIframe);
          }, 0);
        }
      }
    });
    // 获取webview桥接1秒时间就足够了
    return Promise.race([webviewBridgePromise, this.timeoutPromise({ timeout: 1000 })]);
  }

  // 超时
  timeoutPromise({ timeout, isReject = false, methodName }) {
    return new Promise((resolve, reject) => {
      // 超时5秒，防止创建WebViewJavascriptBridge超时无任何响应
      setTimeout(() => {
        if (isReject) {
          reject({ errBridgeMsg: 'APP JS-BRIDGE TIMEOUT', errBridgeMethod: methodName });
        } else {
          resolve();
        }
      }, timeout);
    });
  }

  appBridge = async ({ methodName, params = {}, bridgeType, registerCallback, timeout = 3000 }) => {
    const bridgePromise = new Promise((resolve, reject) => {
      if (config['client_env'] !== 'app') {
        return reject({ errBridgeMsg: 'APP ENV ERROR', errBridgeMethod: methodName });
      }
      bridgeType = bridgeType || this.bridgeType.call;
      // 20240430 新增鸿蒙UA判断
      const HARMONY_UA = 'com.lucky.luckincoffee';
      if (this.appType === 'android' || navigator.userAgent.toLowerCase().includes(HARMONY_UA)) {
        // 注册（app调用js方法）
        if (this.bridgeType.register === bridgeType) {
          // 切记methodName不能重复
          window[methodName] = (responseData) => registerCallback?.(responseData);
          resolve();
        } else {
          // 其他默认 调用（js调用app方法）
          const windowNameSpace = window[this.namespace];
          // 判断命名空间会否存在 和 该方法是否存在
          if (typeof windowNameSpace === 'object' && typeof windowNameSpace[methodName] === 'function') {
            // 判断是否有参数，没有参数一定要为空参数
            if (Object.keys(params).length > 0) {
              return resolve(windowNameSpace[methodName](JSON.stringify(params)));
            } else {
              return resolve(windowNameSpace[methodName]());
            }
          }
          return reject({ errBridgeMsg: 'APP JS-BRIDGE METHOD ERROR', errBridgeMethod: methodName });
        }
      } else if (['iphone', 'ipad'].includes(this.appType)) {
        // 不会有异常
        this.getAppBridge().then((bridge) => {
          if (bridge) {
            // 注册（app调用js方法）
            if (this.bridgeType.register === bridgeType) {
              window[methodName] = (responseData) => registerCallback?.(responseData);
              resolve();
            } else {
              // 调用（js调用app方法）
              bridge.callHandler(`${this.namespace}.${methodName}`, params, (data) => {
                resolve(data?.content);
              });
              // 当无返回值，APP不会执行回调函数，即不会resolve，因此需要单独resolve，否则会reject调用超时
              if (WHITE_LIST_RESOLVE_BRIDGE_METHODS.includes(methodName)) {
                resolve();
              }
            }
          } else {
            reject({ errBridgeMsg: 'WEBVIEW JS-BRDIGE CREATE TIMEOUT IN IOS', errBridgeMethod: methodName });
          }
        });
      } else {
        return reject({ errBridgeMsg: `APP ENV ERROR, CURRENT ENV IS ${this.appType}`, errBridgeMethod: methodName });
      }
    });
    return Promise.race([bridgePromise, this.timeoutPromise({ timeout, isReject: true, methodName })]);
  };
}

export default (namespace) => new AppBridge(namespace);
