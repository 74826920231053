import AJAX from '@spa/utils/http';
import axios from 'axios';
import config from '@spa/config';
import utils from '../index';
import { HTTP_METHOD_CONFIG } from '@/spa/config';

const Protocol = {
  download: {
    plist: 'itms-services://?action=download-manifest&url=',
  },
  wakeUp: {
    app: 'luckycoffee://host',
    oa: 'emobile://m.lkcoffee.com',
    partner: 'luckinpartner://m.lkcoffee.com',
  },
};

const Clients = {
  app: {
    download: {
      // 苹果商店app地址
      appStore: 'https://itunes.apple.com/cn/app/id1296749505?pkgname=com.lucky.luckyclient',
      // 应用宝商店app地址
      qqStore: 'https://a.app.qq.com/o/simple.jsp?pkgname=com.lucky.luckyclient',

      // 本地服务器下载安卓端, 从api接口获取apk地址
      android: '',
      // 本地服务器下载ios端, 从api接口获取plist地址
      ios: '',
      // 华为应用商店
      huaweiStore: 'https://appgallery.huawei.com/app/C100192545',
      // 鸿蒙应用商店
      harmonyStore: 'https://appgallery.huawei.com/app/detail?id=com.lucky.luckincoffee',
    },
    wakeUp: {
      // 苹果app唤醒地址
      ios: Protocol.wakeUp.app,
      // 安卓app唤醒地址
      android: `${Protocol.wakeUp.app}/?isLuckinDeeplink=true&webUrl=${window.location.href}`,
      // chrome浏览器中安卓app唤醒地址
      androidChrome: 'intent://scan/#Intent;scheme=luckycoffee;package=com.lucky.luckyclient;end',
    },
    lsopName: {
      ios: 'luckyclientios',
      android: 'luckyclientandroid',
      harmonyos: 'luckyclientharmonyos',
    },

    // 更新为最新ams地址
    apiUrl: '/resource/ams/v2/version/client',
    apiPath: 'capi',
  },
  partner: {
    download: {
      // 苹果商店app地址
      appStore: 'https://apps.apple.com/cn/app/id1479497803',
      // 应用宝商店app地址
      qqStore: 'https://a.app.qq.com/o/simple.jsp?pkgname=com.lucky.luckinpartner',
      // 本地服务器下载安卓端, 从api接口获取apk地址
      android:
        'https://img.luckincoffeecdn.com/group1/M01/47/5C/CtQLO13Sc-eAXnOfAdIexgeKHhY437.apk?filename=Android_luckyPartner_Prod-1.6.1.0-release_1610_20_luckincoffee_sign.apk',
      // android: 'https://img.luckincoffeecdn.com/group1/M01/F4/66/CtQLPF26b2CABjMKAcJGU-vvYuE624.apk?filename=Android_luckyPartner_Prod-1.5.3.0-release_1530_20_luckincoffee_sign.apk',
      // 本地服务器下载ios端, 从api接口获取plist地址
      ios: '',
    },
    wakeUp: {
      // 苹果app唤醒地址
      ios: Protocol.wakeUp.partner,
      // 安卓app唤醒地址
      android: Protocol.wakeUp.partner,
      // chrome浏览器中安卓app唤醒地址
      androidChrome: 'intent://scan/#Intent;scheme=luckinpartner;package=com.lucky.luckinpartner;end',
    },
    // invokerCode: {
    //     ios: 841,
    //     andorid: 840
    // },
    apiUrl: '/resource/partnerapi/base/start2',
    apiPath: 'partnerapi',
  },
  oa: {
    download: {
      // 本地服务器下载安卓端, 从api接口获取apk地址
      andoriod: '',
      // 本地服务器下载地址，ios地址
      ios: '',
    },
    wakeUp: {
      // 苹果app唤醒地址
      ios: Protocol.wakeUp.oa,
      // 安卓app唤醒地址
      android: Protocol.wakeUp.oa,
      // chrome浏览器中安卓app唤醒地址
      androidChrome: 'intent://scan/#Intent;scheme=emobile;package=com.weaver.emobile6.luckintest;end',
    },
    invokerCode: {
      ios: 600,
      // andorid: 610
      andorid: 620,
    },
    project: {
      ios: 'luckyoaios',
      android: 'luckyoaandroid',
    },
    apiUrl: '/resource/s/sys/oaStart',
    apiPath: 'sapi',
    amsKey: 'amsOA',
    amsConfig: null,
  },
  dispatche: {
    download: {
      // 本地服务器下载安卓端, 从api接口获取apk地址
      android: '',
      ios: '',
    },
    invokerCode: {
      andorid: 300,
      ios: 310,
    },
    project: {
      ios: 'luckygoios',
      android: 'luckygoandroid',
    },
    apiUrl: '/resource/s/sys/start2',
    apiPath: 'sapi',
    amsKey: 'amsLuckyGo',
    amsConfig: null,
  },
  employee: {
    download: {
      // 本地服务器下载安卓端, 从api接口获取apk地址
      android: '',
      ios: '',
    },
    invokerCode: {
      // andorid: 830,
      andorid: 832,
      ios: 831,
    },
    project: {
      ios: 'luckyemployeeios',
      android: 'luckyemployeeandroid',
    },
    apiUrl: '/resource/empapi/base/start2',
    apiPath: 'empapi',
    amsKey: 'amsEmployee',
    amsConfig: null,
  },
  store: {
    download: {
      // 本地服务器下载安卓端, 从api接口获取apk地址
      android: '',
      // 本地服务器下载ios端 ， 门店没有ios端
      // ,ios: ''
    },
    invokerCode: {
      andorid: 100,
    },
    apiUrl: '/resource/s/sys/start2',
    apiPath: 'sapi',
  },
};

// 心跳检测
let heartbeat;
// 定时器
let timer;
// 判断app安装情况
let appDetectorIframe = null;
// 下载目标
// let target = window.location.href.indexOf('/oa/') > -1 ? OA : APP;

// 当前要下载的客户端id
let target;
// AMS接口地址
const AMSReqUrl = '/resource/ams/version/package/url';

class Download {
  // 获取AMS配置，若为1，则请求AMS，若为0，则请求ADMIN
  getAmsConfig(amsKey) {
    return new Promise((resolve) => {
      axios({
        url: '/cfcenter/datasource',
        method: 'post',
        data: {
          dskey: 'luckyfe.luckyfex.download.ams',
        },
      })
        .then((res) => {
          console.log(res, 'res');
          // 如果接口请求失败，默认从 admin 获取，故设置默认值为 0
          const val = res.data?.dataSourceMap?.['luckyfe.luckyfex.download.ams']?.[amsKey] ?? 0;
          target.amsConfig = val;
          resolve(val);
        })
        .catch((err) => {
          // 如果接口请求失败，默认从 admin 获取，故设置默认值为 0
          resolve(0);
          target.amsConfig = 0;
          console.log(err, 'err');
        });
    });
  }

  createIframe() {
    if (!appDetectorIframe) {
      appDetectorIframe = document.createElement('iframe');
      appDetectorIframe.style.cssText = 'display:none;width:0px;height:0px;';
      appDetectorIframe.id = 'appDetectorIframe';
      document.body.appendChild(appDetectorIframe);
    }
  }

  clearTimer() {
    clearTimeout(timer);
    clearInterval(heartbeat);
  }

  /**
   * 检测用户是否安装了app
   * 如果安装直接打开;
   */
  launcher() {
    const that = this;
    this.createIframe();
    const nowDate = Date.now();
    heartbeat = setInterval(() => {
      if (document.webkitHidden || document.hidden) {
        that.clearTimer();
      }
    }, 150);
    if (this.isIos()) {
      const iosVersion = this.getIosVersion();
      if (iosVersion && iosVersion > 8) {
        this.clearTimer();
        this.download();
        return;
      } else {
        console.log('wake up ios url', target.wakeUp.ios);
        appDetectorIframe.src = target.wakeUp.ios;
      }
    } else {
      console.log('wake up android url', target.wakeUp.android);
      appDetectorIframe.src = target.wakeUp.android;
    }
    timer = setTimeout(() => {
      if (Date.now() - nowDate < 1600) {
        that.clearTimer();
        that.download();
      }
    }, 1500);
  }

  /**
   * 是否是PC
   * @returns {boolean}
   */
  isPC() {
    const userAgent = navigator.userAgent;
    if (userAgent.match(/Android|iPhone|SymbianOS|Windows Phone|iPad|iPod|iTouch/)) {
      return false;
    }
    return true;
  }

  /**
   * 是否是IOS系统
   * @returns {boolean}
   */
  isIos() {
    const userAgent = navigator.userAgent;
    if (userAgent.match(/iPhone|iPad|iPod|iTouch/)) {
      return true;
    }
    return false;
  }

  /**
   * 读取ios版本号
   * @returns {Array|{index: number, input: string}}
   */
  getIosVersion() {
    const versionStr = navigator.userAgent.match(/OS\s\d+/);
    if (versionStr) {
      const iosVersion = versionStr[0].match(/\d+/);
      return iosVersion ? iosVersion[0] : '';
    }
    return '';
  }

  /**
   * 是否在小鹿茶中打开
   * @returns {boolean}
   */
  isLuckinTea() {
    console.log(config['client_brand']);
    return config['client_brand'] === 'tea';
  }

  /**
   * 是否在微信中打开
   * @returns {boolean}
   */
  isWinxin() {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf('MicroMessenger') > 0) {
      return true;
    }
    return false;
  }

  async getAmsConfigPkg(clienId) {
    if (target.pkg) {
      return target.pkg;
    }
    const params = {};
    const targetVersion = utils.getUrlParams('targetVersion') ?? null;
    if (targetVersion) {
      params.targetVersion = targetVersion;
    }
    if (/OpenHarmony/i.test(navigator.userAgent)) {
      window.location.href = target.download.harmonyStore;
      return;
    } else if (this.isIos()) {
      params.lsopName = target.lsopName.ios;
    } else {
      params.lsopName = target.lsopName.android;
    }
    return (target.pkg = AJAX[Clients[clienId].apiPath]({
      url: target.apiUrl,
      params: {
        ...params,
      },
      method: HTTP_METHOD_CONFIG.post,
    }).then((result) => {
      const pkgUrlList = result?.content?.packages;
      // 有对应版本号才下载
      if (pkgUrlList && !!result?.content?.version) {
        // 如果是ios,那还得判断这个客户端是否是发布appStore的，如果pkgUrlList存在appStore的version，则跳appStore的，
        // 不存在也是直接跳，理论上生产不存在这种情况，这里兜底跳下，所以默认ios直接取值appStore.
        // 如果pkgUrlList长度不为1，那肯定是安卓的版本返回了64位的包和32位的包，需要判断系统位数下载对应包
        if (pkgUrlList.length === 1) {
          if (this.isIos()) {
            target.download.ios = target.download.appStore;
          } else {
            target.download.android = pkgUrlList[0]?.url;
          }
        } else {
          const pkg32 = pkgUrlList.find((item) => item.type === 'apk32');
          const pkg64 = pkgUrlList.find((item) => item.type === 'apk64');
          if (navigator.platform.indexOf('32') > -1 || /(arm32|armv7)/i.test(navigator.userAgent)) {
            target.download.android = pkg32?.url || pkg64?.url;
          } else {
            target.download.android = pkg64?.url;
          }
        }
      }
    })).catch((err) => {
      console.error('getAmsConfigPkg fail:', err);
      alert('数据获取失败，请稍后重试');
    });
  }

  async getClientPkg(clienId) {
    const AMSConfig = target.amsKey ? target.amsConfig ?? (await this.getAmsConfig(target.amsKey)) : 0;
    let url, path;
    if (target.pkg) {
      return target.pkg;
    }

    url = AMSConfig === 1 ? AMSReqUrl : target.apiUrl; // '/resource/s/sys/start2';
    path = target.apiPath; // '/sapi';
    const params = {};
    if (AMSConfig === 1) {
      const query = window.location.search;
      const searchParams = new URLSearchParams(query);
      const type = searchParams.get('type') || 'prod';
      params.type = type;
      params.project = target.project[this.isIos() ? 'ios' : 'android'];
    } else {
      params.version = 0;
      params.empId = 0;
      // invokerCode在除app端外，其他三个端是需要传的，具体code在Clients里有对应的配置.
      if (target.invokerCode) {
        params.invokerCode = this.isIos() && target.invokerCode.ios ? target.invokerCode.ios : target.invokerCode.andorid;
      }
    }
    console.log(params, 'params');
    return (target.pkg = AJAX[Clients[clienId].apiPath]({
      url: url,
      params: {
        path,
        handleMsg: false,
        ...params,
      },
    }).then((result) => {
      console.log(result, 'result');
      const pkgUrl = result?.content?.version?.address || result?.content?.url;
      if (pkgUrl) {
        // 如果是ios,那还得判断这个客户端是否是发布appStore的，如果是appStore发版的，不需要走ios
        if (this.isIos() && typeof target.download.ios !== 'undefined') {
          // 发布线上的端如用户端, 必须从appstore下载， 接口返回会是apk地址.
          if (target.download.appStore) {
            target.download.android = pkgUrl;
          } else {
            // 没有appStore 的说明是发布到企业服务内的,才是plist形式下载更新
            target.download.ios = Protocol.download.plist + pkgUrl;
          }
        } else {
          target.download.android = pkgUrl;
        }

        return result.content.version;
      } else {
        console.error('getClientPkg fail:', result);
        alert('数据获取失败，请稍后重试');
      }
    }));
  }
  /**
   * 仅下载app
   */
  async download() {
    // 微信扫描
    if (this.isIos()) {
      if (target.download.appStore) {
        window.location.href = target.download.appStore;
      } else {
        // 比如配送端，门店端只有andorid版本,那就不会有download.ios
        window.location.href = target.download.ios || target.download.android;
      }
    } else {
      if (this.isWinxin() && target.download.qqStore) {
        window.location.href = target.download.qqStore;
      } else if (navigator.userAgent.includes('HUAWEI') && target.download.huaweiStore) {
        window.location.href = target.download.huaweiStore;
      } else {
        window.location.href = target.download.android;
      }
    }
  }

  run(clientId) {
    target = Clients[clientId];
    if (!target) {
      return;
    }
    // 通过接口获取下载地址，并且赋给当前target ，然后就可以根据环境进行下载了
    // 如果ams的接口换成最新路径，调用请求getAmsConfigPkg
    let fetchPkg;
    if (clientId === 'app') {
      fetchPkg = this.getAmsConfigPkg(clientId);
    } else {
      fetchPkg = this.getClientPkg(clientId);
    }
    fetchPkg.then(() => {
      // 不在微信里，且有wakeup配置的端才走唤起端逻辑
      if (!this.isWinxin() && target.wakeUp) {
        this.launcher();
      } else {
        // 其余情况都走下载
        this.download();
      }
    });
  }

  init(clientId) {
    target = Clients[clientId];
    if (!target) {
      return;
    }
    // 通过接口获取下载地址，并且赋给当前target ，然后就可以根据环境进行下载了
    return this.getClientPkg(clientId);
  }
}

export default new Download();
