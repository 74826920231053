'use strict';
import crypto from '@spa/utils/crypto';
import cookieUtils from './cookie';
import storageKeys, { keyTypes } from './storageKey';

const SK = 'sk_WOA_c@';

/**
 * cookie 存储
 */
class CookieStorage {
  get(k) {
    return cookieUtils.getCookies(k);
  }

  set(k, v, e) {
    return cookieUtils.setCookies(k, v, e);
  }

  remove(k) {
    return cookieUtils.removeCookies(k);
  }
}

/**
 * localstorage 存储
 */
class LStorage {
  get(k) {
    return localStorage.getItem(k);
  }

  set(k, v) {
    localStorage.setItem(k, v);
  }

  remove(k) {
    localStorage.removeItem(k);
  }
}

/**
 * sessionstorage 存储
 */
class SStorage {
  get(k) {
    return sessionStorage.getItem(k);
  }

  set(k, v, e) {
    sessionStorage.setItem(k, v);
  }

  remove(k) {
    sessionStorage.removeItem(k);
  }
}

class Storage {
  constructor() {
    this.cookieS = new CookieStorage();
    this.localS = new LStorage();
    this.sessionS = new SStorage();
    this.supportLocalStore = this.localStorageSupported();
  }

  isCookieStorage(s) {
    return s && s instanceof CookieStorage;
  }

  isDebug() {
    return false;
  }

  set(key, value, e) {
    let configKey = this.getKeyConfig(key, value);
    let { storeKey, awCookie } = configKey;

    // 获取存储
    const store = this.getStorage(configKey);
    let storeValue = { v: configKey.value };
    if (e && !this.isCookieStorage(store) && this.supportLocalStore) {
      storeValue.e = Date.now() + e * 1e3;
    }

    if (this.supportLocalStore) {
      let storeValueStr = JSON.stringify(storeValue);
      if (!this.isDebug() && !this.isCookieStorage(store)) {
        storeValueStr = crypto.en(storeValueStr, SK).toString();
      }
      store.set(storeKey, storeValueStr, e);
    } else {
      if (awCookie) {
        this.cookieS.set(storeKey, storeValue, e);
      } else {
        // throw new Error('该模式下不支持此存储!');
        console.error('该模式下不支持此存储, key:' + storeKey);
        return;
      }
    }

    return configKey.value;
  }

  get(key) {
    let configKey = this.getKeyConfig(key);
    let { storeKey, awCookie } = configKey;
    const store = this.getStorage(configKey);
    let value;
    try {
      if (this.supportLocalStore) {
        value = store.get(storeKey);
      } else {
        if (awCookie) {
          value = this.cookieS.get(storeKey);
        } else {
          // throw new Error('该模式下不支持此存储!');
          console.error('该模式下不支持此存储, key:' + storeKey);
          return;
        }
      }

      if (value) {
        if (!this.isDebug() && !this.isCookieStorage(store)) {
          value = crypto.de(value, SK).toString();
        }

        value = JSON.parse(value);
        if (value.e && Date.now() >= value.e) {
          this.remove(key);
          return;
        }
        return value.v;
      }
    } catch (e) {
      console.error('存储失败key:' + storeKey, e);
      return;
    }

    return value;
  }

  remove(key) {
    let configKey = this.getKeyConfig(key);
    const store = this.getStorage(configKey);

    if (this.supportLocalStore) {
      store.remove(configKey.storeKey);
      return;
    }

    if (configKey.awCookie) {
      this.cookieS.remove(configKey.storeKey);
    } else {
      console.error('该模式下不支持此存储, key:' + configKey.storeKey);
      // throw new Error('该模式下不支持此存储!');
    }
  }

  removeAll(filter) {
    storageKeys.forEach(item => {
      if (filter && typeof filter === 'function' && filter(item)) {
        return;
      }
      this.remove(item);
    });
  }

  getKeyConfig(k, v) {
    let storeKey = k;
    let value = v;
    let key = k;
    let awCookie = false;
    let type = keyTypes.local;
    if (typeof k === 'object') {
      storeKey = k.key + (k.skey ? k.skey : '');
      value = k.value || value;
      key = k.key;
      type = k.type;
      awCookie = k.awCookie;
    } else {
      throw new Error('key必须是对象!');
    }
    // const configKey = this.storeKeys[key] || {};
    return {
      key,
      storeKey,
      type,
      value,
      awCookie,
    };
  }

  // 通过key的配置，获取存储的方式
  getStorage(keyConfig) {
    const userAgent = window.navigator.userAgent.toLowerCase();
    // oppo vivo 只支持localstorage
    const onlyLocal = userAgent.includes('vivo') || userAgent.includes('oppo');
    if (keyConfig && keyConfig.type) {
      if (keyConfig.type === keyTypes.cookie) {
        return this.cookieS;
      } else if (keyConfig.type === keyTypes.session && !onlyLocal) {
        return this.sessionS;
      }
    }
    return this.localS;
  }

  localStorageSupported() {
    const storage = window.sessionStorage;
    try {
      storage.setItem('z', '0');
      storage.removeItem('z');
      return true;
    } catch (error) {
      return false;
    }
  }
}

export default new Storage();
