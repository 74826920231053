/**
 * @author linzl
 * 该文件勿动，有修改或者变更，找我协调处理
 */

import axios from 'axios';
import LuckToast from '@spa/components/LuckToast';
import config, { HTTP_METHOD_CONFIG, APIS_NAME_CONFIG } from '@spa/config';
import cookie from '@spa/utils/cache/cookie';
import adsReport from '@spa/utils/ads-report';
import appApi from '@spa/utils/app-api';
import { manualTrackApiHandle } from '@spa/utils/lucky-track';

const privacyApi = Symbol('privacyApi');
class Ajax {
  // 查询地址栏参数
  getQueryString(name) {
    const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
    const r = window.location.search.substring(1).match(reg);
    if (r != null) return decodeURI(r[2]);
    return null;
  }

  resetBaseInfo() {
    sessionStorage?.removeItem('deptId');
    sessionStorage?.removeItem('memberId');
  }

  /**
   * 小鹿茶api (这样开接口，方便调用时，知道是哪个接口)
   */
  teacapi(options) {
    const { url, params = {}, method } = options;
    return this[privacyApi](url, params, config[APIS_NAME_CONFIG.teacapi], config[method]);
  }
  /**
   * 咖啡capi
   */
  capi(options) {
    const { url, params = {}, method } = options;
    return this[privacyApi](url, params, config[APIS_NAME_CONFIG.capi], config[method]);
  }
  /**
   * 批量买券
   */
  walletapi(options) {
    const { url, params = {}, method } = options;
    return this[privacyApi](url, params, config[APIS_NAME_CONFIG.walletapi], config[method]);
  }
  /**
   * 活动api
   */
  activityApi(options) {
    const { url, params = {}, method } = options;
    params.clientBrand = config.clientBrand;
    return this[privacyApi](url, params, config[APIS_NAME_CONFIG.activityapi], config[method]);
  }

  /**
   * sapi
   */
  sapi(options) {
    const { url, params = {}, method } = options;
    return this[privacyApi](url, params, config[APIS_NAME_CONFIG.sapi], config[method]);
  }

  /**
   * 小鹿茶运营合作伙伴
   */
  partnerapi(options) {
    const { url, params = {}, method } = options;
    return this[privacyApi](url, params, config[APIS_NAME_CONFIG.partnerapi], config[method]);
  }

  /**
   * 门店执勤API
   */
  empapi(options) {
    const { url, params = {}, method } = options;
    return this[privacyApi](url, params, config[APIS_NAME_CONFIG.empapi], config[method]);
  }
  /**
   * 电商API
   */
  ecapi(options) {
    const { url, params = {}, method } = options;
    return this[privacyApi](url, params, config[APIS_NAME_CONFIG.ecapi], config[method]);
  }

  // 简单请求
  proxy(options) {
    const { url, params = {}, method } = options;
    return this[privacyApi](url, params, '', config[method]);
  }

  /**
   * 接口请求
   * @param {string} url
   * @param {object} params
   * @param {string} apiPath  default: /capi
   * @param {string} method   default: get
   */
  async [privacyApi](url, params = {}, apiPath = '', method = config[HTTP_METHOD_CONFIG.get]) {
    // 判断是否需要获取广告上报数据
    if (config.needAdsReportApis.includes(url)) {
      const adParams = adsReport.handleAdsReportParams();
      params.adParams = adParams || {};
    }
    if (method === config[HTTP_METHOD_CONFIG.post]) {
      const csrf = cookie.getCookies(`LK_${process.env.NODE_LUCKY_ENV}_csrfToken`) || window._csrf;
      if (csrf) {
        url = url.indexOf('?') > 0 ? url + `&_csrf=${csrf}` : url + `?_csrf=${csrf}`;
      }
    } else {
      // 有可能参数传null，那么则默认get方法
      method = config[HTTP_METHOD_CONFIG.get];
    }
    let opts = {
      url: url,
      params: method === config[HTTP_METHOD_CONFIG.post] ? null : params, // 请求api专用
      data: method === config[HTTP_METHOD_CONFIG.post] ? params : null, // 非请求api使用
      path: apiPath,
      needError: false, // 是否处理非业务错误
      loading: params.loading === false ? false : true, // 是否显示loading
      loadingText: '',
      handleMsg: params.handleMsg === false ? false : true, // 默认是通用处理, false不处理,
      method: method || config[HTTP_METHOD_CONFIG.get],
    };

    const showLoading = () => {
      if (opts.loading) {
        LuckToast.loading();
      }
    };

    const hideLoading = () => {
      if (opts.loading) {
        LuckToast.hide();
      }
    };

    if (typeof url === 'object') {
      Object.assign(opts, url);
    }
    // fix bug LUCKY-32533, antd-mobile v2.3.0的bug, https://github.com/ant-design/ant-design-mobile/issues/2930
    opts.loading = false;

    showLoading();
    // 增加时间戳防止缓存
    if (opts.params) {
      opts.params['_'] = Date.now();
    }
    if (opts.data) {
      opts.data['_'] = Date.now();
    }

    const curlOptions = {
      url: opts.url,
      baseURL: opts.path,
      method: opts.method,
      timeout: 20000,
      paramsSerializer: (queryParams) => {
        if (queryParams) {
          delete queryParams.handleMsg;
        }
        // 目前query 传递数组 会存在问题，同时此次必须做uri编码，否则转码会有问题，因此本次包装一层queryParams
        const queryParamsStr = JSON.stringify(queryParams);
        return `queryParamsStr=${encodeURIComponent(queryParamsStr)}`;
      },
      headers: {
        'x-csrf-token': window._csrf || cookie.getCookies('csrfToken'),
        'X-LK-MID': config.member_id || '',
      },
    };
    if (opts.method === 'post') {
      curlOptions.data = opts.wrapQ
        ? {
            q: JSON.stringify(opts.data),
          }
        : opts.data;
    } else {
      curlOptions.params =
        opts.data ||
        (opts.wrapQ
          ? {
              q: JSON.stringify(opts.params),
            }
          : opts.params);
    }
    // 手动埋点-请求开始
    manualTrackApiHandle('apiStart', opts);
    return axios(curlOptions)
      .then((resp) => {
        hideLoading();
        // 判断条件统一为 code === 1
        if (resp?.data?.code === 1) {
          // 手动埋点-成功
          manualTrackApiHandle('apiSuccess', resp);
        } else {
          // 失败-手动埋点
          manualTrackApiHandle('apiFail', resp);
        }
        if (resp && resp.data) {
          // 通用错误处理
          if (resp.data.status !== 'SUCCESS' && opts.handleMsg) {
            let msg = resp.data.msg || '业务处理错误!';
            if (resp.data.code === 400) {
              msg = '请求服务器超时, 请稍后再试';
            }
            setTimeout(() => {
              LuckToast.info(msg, 2, null, false);
            }, 200);
            return;
          }
          const isOpenWebp = !!config['luckyfe.wap.webp']?.webp || false;
          if (isOpenWebp && window.isSupportWebp) {
            const isIos = !!window.sysType?.ios;
            const imgReg = isIos
              ? /(http)(s)?:\/\/(img|static|mktoss)(.*?)\.(png|jpg|jpeg|bmp)/gi
              : /(http)(s)?:\/\/(img|static|mktoss)(.*?)\.(png|jpg|gif|jpeg|bmp)/gi;
            const tempResult = JSON.stringify(resp.data);
            const parseResult = tempResult.replace(imgReg, (imgUrl) => {
              if (/^(http)(s)?:\/\/(mktoss)\./.test(imgUrl)) {
                const ossSubfix = '?x-oss-process=image/format,webp';
                return `${imgUrl}${ossSubfix}`;
              } else {
                return `${imgUrl}_.webp`;
              }
            });
            return JSON.parse(parseResult);
          } else {
            return resp.data;
          }
        }
        return;
      })
      .catch((error) => {
        hideLoading();
        // 失败-手动埋点
        manualTrackApiHandle('apiFail', error);
        // 是否需要处理提示信息
        if (opts.handleMsg) {
          if (error?.response?.status === 500) {
            setTimeout(() => {
              LuckToast.info('服务异常', 2);
            }, 200);
          } else {
            setTimeout(() => {
              LuckToast.info('网络连接失败，请重试', 2);
            }, 200);
          }
        }
        return;
      });
  }
}

export default new Ajax();
